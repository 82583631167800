import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ActionType, createAction, getType } from 'typesafe-actions';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';

import SearchData, { FlightSearchType } from 'models/SearchData';
import { AirportSuggestion, Suggestion } from 'models/Suggestion';
import { setSearchDataCookie } from 'utils/searchData/cookie';
import { ExternalSearchData, ExternalSearchDate } from 'models/incomingData/SearchData';
import { MomentDate } from 'models/Date';
import moment from 'moment';
import TimeOfDay from 'models/SearchData/enums/TimeOfDay';
import SearchType from 'models/SearchData/enums/SearchType';

// Contains redux functions for air search data

export type AirSearchDataState = Readonly<SearchData<AirportSuggestion>>;

export interface StoreWithAirSearchDataState {
    airSearchData: AirSearchDataState;
}

// Define actions:
export const actions = {
    // now that this is exported, we want to make it clear that this doesn't update the search data cookie
    // which is really only useful if the cookie has already been updated by, say, the flightjs app
    updateAirSearchDataWithoutUpdatingCookie: createAction('awd/airSearchData/UPDATE', resolve => {
        return (airSearchData: SearchData<AirportSuggestion>) => resolve({ airSearchData });
    })
};
export type AirSearchDataAction = ActionType<typeof actions>;

export default function reducer(
    state: AirSearchDataState = {},
    action: AirSearchDataAction
): Readonly<AirSearchDataState> {
    if (action.type === getType(actions.updateAirSearchDataWithoutUpdatingCookie)) {
        return { ...state, ...action.payload.airSearchData };
    } else {
        return state;
    }
}

// Selectors:
export function getAirSearchData(state: StoreWithAirSearchDataState): SearchData<AirportSuggestion> {
    return state.airSearchData;
}

// Action Creators:
export function updateAirSearchData(
    searchData: SearchData<AirportSuggestion>
): ThunkAction<AirSearchDataAction, StoreWithAirSearchDataState, void, AirSearchDataAction> {
    setSearchDataCookie(searchData);

    return (dispatch: ThunkDispatch<StoreWithAirSearchDataState, void, AirSearchDataAction>) => {
        return dispatch(actions.updateAirSearchDataWithoutUpdatingCookie(searchData));
    };
}

export function importExternalSearchDataWithAirport(
    externalSearchData: ExternalSearchData
): ThunkAction<AirSearchDataAction, StoreWithAirSearchDataState, void, AirSearchDataAction> {
    return (dispatch: ThunkDispatch<StoreWithAirSearchDataState, void, AirSearchDataAction>) => {
        const searchData = translateExternalSearchDataWithAirports(externalSearchData);
        return dispatch(actions.updateAirSearchDataWithoutUpdatingCookie(searchData));
    };
}

// Translators
function translateExternalSearchDate(d: ExternalSearchDate): MomentDate {
    const { date, time } = d,
        momentDate = moment(date, 'YYYY-MM-DD');
    let timeInt = parseInt(time as string, 10);

    // We don't really use time on our site, so we can easily handle noon but
    // we can safely disregard of time of day values that aren't integers
    if (time) {
        timeInt = time === TimeOfDay.NOON ? 12 : parseInt(time, 10);

        if (isFinite(timeInt)) {
            momentDate.hour(timeInt);
        }
    }

    return momentDate;
}

export function translateBaseExternalSearchData<T extends Suggestion>(
    data: ExternalSearchData
): Exclude<SearchData<T>, 'location1' | 'location2'> {
    const { searchDate1, searchDate2, nonStop, provider, rooms, serviceClass, travelers } = data,
        date1 = searchDate1 && translateExternalSearchDate(searchDate1),
        date2 = searchDate2 && translateExternalSearchDate(searchDate2);

    return pickBy(
        {
            date1,
            date2,
            nonStop,
            provider,
            rooms,
            serviceClass,
            travelers
        },
        v => !isNil(v)
    );
}

/**
 * Translates the {@link ExternalSearchData} entity that is used by the backend
 * to the {@link SearchData} entity primarily used by the frontend.
 *
 * @param data
 */
export function translateExternalSearchDataWithAirports(data: ExternalSearchData): SearchData<AirportSuggestion> {
    let airport1, airport2;

    if (data.location1CityId) {
        const {
            location1AirportCode,
            location1AirportId,
            location1CityDisplayName,
            location1CityId,
            location1Name,
            location1DepartureFareEligible
        } = data;

        // This is kinda hacky: we're telling the TS compiler that we happen to
        // know all these variables are defined. But the assumption here is that if
        // on of the location parameters (e.g. location1CityId) is defined, then all
        // other locations parameters will be defined. Unfortunately, I couldn't
        // find a way to make this more type-safe with a type
        airport1 = {
            text: location1Name!,
            suggestion: {
                id: location1AirportId!,
                parentId: location1CityId!,
                airportCode: location1AirportCode!,
                displayName: location1Name!,
                parentDisplayName: location1CityDisplayName!,
                departureFareEligible: location1DepartureFareEligible!
            }
        };
    }

    if (data.location2CityId) {
        const {
            location2AirportCode,
            location2AirportId,
            location2CityDisplayName,
            location2CityId,
            location2Name,
            location2DepartureFareEligible
        } = data;

        airport2 = {
            text: location2Name!,
            suggestion: {
                id: location2AirportId!,
                parentId: location2CityId!,
                airportCode: location2AirportCode!,
                displayName: location2Name!,
                parentDisplayName: location2CityDisplayName!,
                departureFareEligible: location2DepartureFareEligible!
            }
        };
    }

    return {
        location1: airport1,
        location2: airport2,
        ...translateBaseExternalSearchData(data),
        searchType: SearchType.AIR,
        flightSearchType: data.flightSearchType || FlightSearchType.ROUNDTRIP
    };
}
