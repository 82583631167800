import SentryConfig from 'models/SentryConfig';
import { Middleware } from 'redux';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/browser';
import { ConfigState } from 'ducks/config';
import { AirSearchDataState } from 'ducks/airSearchData';
import { UserState } from 'ducks/user';
import { HotelSearchDataState } from 'ducks/hotelSearchData';

/**
 * This represents pieces of redux state that we expect to be present for every app. Used in this middleware
 * when determining which parts of state to attach to to a Sentry error.
 */
interface StateWithCommonProperties {
    config?: ConfigState;
    airSearchData?: AirSearchDataState;
    hotelSearchData?: HotelSearchDataState;
    user?: UserState;
}

export default function sentryMiddleware(sentryConfig: SentryConfig) {
    if (!sentryConfig || !sentryConfig.enabled) {
        // @ts-ignore - ignore unused store variable
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const noOp: Middleware = store => next => action => next(action);
        return noOp;
    }

    // For available options, see https://github.com/vidit-sh/redux-sentry-middleware
    return createSentryMiddleware(Sentry, {
        // The Redux info that this middleware attaches will be stripped out if the Sentry event is too large
        // (see utils/sentry). To prevent that from happening, we attach a subset of Redux state here instead
        // of the whole thing.
        stateTransformer: (state: StateWithCommonProperties) => {
            const { config, airSearchData, hotelSearchData, user } = state,
                subscriber = user && user.subscriber;
            return {
                config,
                airSearchData,
                hotelSearchData,
                // Only send a limited amount of subscriber data to Sentry - don't include info like name or email
                subscriber: subscriber && subscriber.trackingCode
            };
        }
    });
}
