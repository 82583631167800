import { MomentDate } from 'models/Date';
import BaseSearchData from './Base';
import { Suggestion, SuggestionDecorator } from 'models/Suggestion';
import { Optional } from 'utility-types';

export interface SearchDataLocations<T extends Suggestion> {
    location1: SuggestionDecorator<T>;
    location2: SuggestionDecorator<T>;
}

interface SearchData<T extends Suggestion> extends BaseSearchData, Partial<SearchDataLocations<T>> {
    date1?: MomentDate;
    date2?: MomentDate;
}

export type SearchDataWithRequiredLocations<T extends Suggestion> = SearchData<T> & Required<SearchDataLocations<T>>;

export type SearchDataWithRequiredLocation1<T extends Suggestion> = SearchData<T> &
    Optional<SearchDataLocations<T>, 'location2'>;

export type SearchDataWithRequiredLocation2<T extends Suggestion> = SearchData<T> &
    Optional<SearchDataLocations<T>, 'location1'>;

export default SearchData;
export { default as FlightSearchType } from './enums/FlightSearchType';
export { default as FlightServiceClass } from './enums/FlightServiceClass';
export { default as Rooms } from './enums/Rooms';
export { default as SearchType } from './enums/SearchType';

export function isSearchDataWithLocations<T extends Suggestion>(
    searchData: SearchData<T>
): searchData is SearchDataWithRequiredLocations<T> {
    return !!(searchData.location1 && searchData.location2);
}

export function isSearchDataWithLocation1<T extends Suggestion>(
    searchData: SearchData<T>
): searchData is SearchDataWithRequiredLocation1<T> {
    return !!searchData.location1;
}

export function isSearchDataWithLocation2<T extends Suggestion>(
    searchData: SearchData<T>
): searchData is SearchDataWithRequiredLocation2<T> {
    return !!searchData.location2;
}
