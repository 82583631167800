export enum FareSortField {
    LOCATION = 'location',
    PRICE = 'price'
}

export enum FareSortDir {
    ASC = 'asc',
    DESC = 'desc'
}

export default interface FareSort {
    field: FareSortField;
    direction: FareSortDir;
}
