/**
 * Frontend representation of the values in NewsletterProductId.java
 */
enum NewsletterProductId {
    AWD_DEPARTURE = 2849536,
    AWD_ARRIVAL = 2849537,
    AWD_CITY_TO_CITY = 2849538,
    AWD_UPDATES = 6577913,
    AWD_INSIDER = 17691557,
    HWD_UPDATES = 20723823,
    TRIPWATCH = 18307197,
    WEEKENDER = 41721537,
    AWD_SPECIAL_OFFER = 34714256,
    HWD_CITY = 20954942,
    JS_GENERAL = 28114192,
    JS_INSPIRATION = 28630755,
    JS_DEALS = 28630756,
    ST_UPDATES = 6577851,
    ST_TRIP_REMINDER = 3997638,
    ST_DEAL_ALERT = 2473393,
    ST_SPOTLIGHT = 27494324,
    FVC_INSIDER = 41721529,
    FVC_DEALS = 41721530,
    FVC_MEMBER_OFFER = 41721531,
    FVC_UPDATES = 41721527,
    OYS_GENERAL = 41721526,
    OYS_HOTEL_DEALS = 41721533,
    OYS_UPDATES = 41721534,
    AWDUK_DEPARTURE = 2849539,
    AWDUK_CITY_TO_CITY = 2849545,
    AWDUK_HWD_CITY = 37786684,
    AWDUK_TRIPWATCH = 38793252,
    AWDUK_UPDATES = 7717748
}

export default NewsletterProductId;
