import { initializeApp } from 'utils/app';
import { AwdWindow } from 'models/AwdWindow';

declare const window: AwdWindow;

initializeApp({
    domain: window.location.host,
    pageData: window.pageData,
    startApp: () => {
        // app code should only be loaded after the polyfill
        const app = require('./app');
        app.start();
    }
});
