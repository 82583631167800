import React from 'react';
import { FormattedMessage } from 'react-intl';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import Button from 'components/Button';
import { DateFormat } from 'constants/date-formats';
import { Suggestion } from 'models/Suggestion';
import { RecentSearchProps } from '../shared/props';
import msgs from './messages';
import './styles.scss';

export default function RecentSearchWide<T extends Suggestion>({ searchData, onClick }: RecentSearchProps<T>) {
    const { location1, location2, date1, date2 } = searchData;

    return (
        <div className='recent-search__container recent-search__container--wide do_no_popunder'>
            <div className='recent-search recent-search--wide'>
                <div className='recent-search__cta-section'>
                    <div className='recent-search__icon'>
                        <AwdIcon name={AwdIconName.Refresh} />
                    </div>
                    <div className='recent-search__cta'>
                        <FormattedMessage {...msgs.cta} />
                    </div>
                </div>
                <div className='recent-search__info-section'>
                    <div className='recent-search__locations'>
                        <FormattedMessage
                            {...msgs.locations}
                            values={{ origin: location1.text, destination: location2.text }}
                        />
                    </div>
                    {date1 && date2 && (
                        <div className='recent-search__dates'>
                            <span>
                                {date1.format(DateFormat.monthDayText)} - {date2.format(DateFormat.monthDayText)}
                            </span>
                        </div>
                    )}
                </div>
                <div className='recent-search__search-section'>
                    <div className='recent-search__button'>
                        <Button text={msgs.searchButton} onClick={onClick} />
                    </div>
                </div>
            </div>
        </div>
    );
}
