import React from 'react';
import './styles.scss';

// To add new colors, update this enum and this component's css file
export enum SpinnerColor {
    Black = 'black',
    Gray = 'gray',
    GrayLight = 'gray-light',
    Orange = 'orange',
    White = 'white',
    Razzmatazz = 'razzmatazz',
    Navy = 'navy'
}

interface SpinnerProps {
    color: SpinnerColor;
}

export default function Spinner({ color = SpinnerColor.Black }: SpinnerProps) {
    return (
        <div className={`spinner spinner-${color}`}>
            <div className='sk-circle'>
                <div className='sk-circle1 sk-child' />
                <div className='sk-circle2 sk-child' />
                <div className='sk-circle3 sk-child' />
                <div className='sk-circle4 sk-child' />
                <div className='sk-circle5 sk-child' />
                <div className='sk-circle6 sk-child' />
                <div className='sk-circle7 sk-child' />
                <div className='sk-circle8 sk-child' />
                <div className='sk-circle9 sk-child' />
                <div className='sk-circle10 sk-child' />
                <div className='sk-circle11 sk-child' />
                <div className='sk-circle12 sk-child' />
            </div>
        </div>
    );
}
