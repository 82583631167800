/**
 * Must be kept in sync with package com.smartertravel.awd.content.SiteId.java
 */
export enum SiteId {
    ST = 3,
    AWD = 200,
    AWD_UK = 206,
    JS = 328,
    FVC = 384,
    OYS = 379
}

export type AwdSiteId = SiteId.AWD | SiteId.AWD_UK;

/**
 * Must be kept in sync with com.smartertravel.awd.model.Site.java
 */
export enum Site {
    AWD = 'awd',
    AWD_UK = 'awduk',
    ST = 'st',
    JS = 'js',
    FVC = 'fvc',
    OYS = 'oys'
}

export type AwdSite = Site.AWD | Site.AWD_UK;
