import { MomentDate } from '../Date';

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awd-fare-service-types/browse/src/main/java/com/smartertravel/awd/faresvc/types/FareBlackout.java
 */
export interface FareBlackout {
    type: FareBlackoutType;
    blackOutDay?: MomentDate;
}

/**
 * These enum values, need to match the ids in the Java instance (PEAK_SUMMER needs to be 1)
 * @see https://stash.st.cognius.net/projects/AWD/repos/awd-fare-service-types/browse/src/main/java/com/smartertravel/awd/faresvc/types/BlackoutType.java
 */
export enum FareBlackoutType {
    PEAK_SUMMER = 1,
    SPRING_BREAK,
    SUMMER_VACATION,
    HOLIDAYS,
    WEEKENDS,
    DATE,
    MONTH
}

export default FareBlackout;
