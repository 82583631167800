import React from 'react';
import './styles.scss';

export enum AwdIconName {
    AirlineGuy = 'icon-airlineguy-01',
    ArrowDottedDown = 'icon-arrow_dotted_down',
    ArrowDottedUp = 'icon-arrow_dotted_up',
    ArrowDown = 'icon-downarrow',
    ArrowLeft = 'icon-left-arrow',
    ArrowsPointingEverywhere = 'icon-uniE60B',
    ArrowRight = 'icon-sideways_arrow',
    ArrowThinDown = 'icon-uniE607',
    ArrowThinLeft = 'icon-uniE6062',
    ArrowThinRight = 'icon-uniE6042',
    ArrowThinUp = 'icon-uniE605',
    ArrowUp = 'icon-uparrow',
    ArrowWithStickLeft = 'icon-ic_arrow_back',
    ArrowWithStickRight = 'icon-ic_arrow_backcopy',
    AwdLogo = 'icon-AWD_Logo',
    AwdLogoFilledIn = 'icon-AWD_Logo--filled-in',
    Bed = 'icon-uniE602',
    Bell = 'icon-bell-01',
    Calendar = 'icon-calendar_clear',
    Calendar2 = 'icon-bookingcalendar',
    Calendar3 = 'icon-icon_calendar',
    CalendarCircle = 'icon-calendar',
    ChatBubble = 'icon-uniE60A',
    Check = 'icon-checkmark',
    CheckCircle = 'icon-check-01',
    CheckCircleBursting = 'icon-icon_check',
    CheckCursive = 'icon-uniE606',
    CheckBadge = 'icon-icon_value',
    Clock = 'icon-clock-01',
    ClockAlarm = 'icon-alarm-01',
    ClockAlarm2 = 'icon-clock-012',
    CreditCard = 'icon-CreditCard_LineDrawn',
    Cutlery = 'icon-icon_amenities',
    Dog = 'icon-dog',
    Dollar = 'icon-uniE603',
    DollarCircle = 'icon-icon_price',
    DollarTag = 'icon-purchase-01',
    Envelope = 'icon-email-01',
    Envelope2 = 'icon-mail-01',
    EnvelopeWithLines = 'icon-envelope_with_lines',
    Ex = 'icon-ex-01',
    ExCircle = 'icon-closecircle',
    ExThin = 'icon-closebutton',
    Eye = 'icon-eye-01',
    Facebook = 'icon-uniE60E2',
    FacebookInverted = 'icon-facebook',
    FlexDates = 'icon-flexdates-01',
    GooglePlus = 'icon-uniE60F2',
    GreatValueBadge = 'icon-uniE613',
    Hamburger = 'icon-uniE604',
    Help = 'icon-icon_help',
    Hotel = 'icon-hotel',
    HwdLogo = 'icon-Hotelwatchdog_Logo',
    Info = 'icon-info',
    LinkedIn = 'icon-uniE612',
    MagnifyingGlass = 'icon-uniE60D',
    NearbyAirportSavingBadge = 'icon-circle_badge-01',
    NearbyAirportSavingsBanner = 'icon-banner-01',
    Paw = 'icon-paw',
    People = 'icon-travelers',
    Person = 'icon-uniE60F',
    Pin = 'icon-pin',
    PinCircle = 'icon-icon_location',
    Pin2 = 'icon-icon_location_pin',
    Pinterest = 'icon-uniE611',
    PlaneAngledDown = 'icon-downplane-01',
    PlaneAngledUp = 'icon-upplane',
    PlaneCircleDown = 'icon-planedown-01',
    PlaneCircleUp = 'icon-planeup-01',
    PlaneFromAboveRight = 'icon-plane2',
    PlaneFromAboveUp = 'icon-uniE608',
    PlaneSilhouetteUp = 'icon-plane',
    PlaneTail = 'icon-airlines-01',
    PlaneUnderlinedDown = 'icon-planedown',
    PlaneUnderlinedUp = 'icon-planeup',
    PlaneLineDrawn = 'icon-Airplane_LineDrawn',
    Refresh = 'icon-refresh',
    Share = 'icon-uniE609',
    ShoppingCart = 'icon-shoppingCart-01',
    SquiggleDown = 'icon-squiggle_down',
    SquiggleUp = 'icon-squiggle_up',
    Star = 'icon-icon_star',
    StarCircle = 'icon-icon_rating',
    StLogo = 'icon-SmarterTravel_Logo',
    StumbleUpon = 'icon-uniE610',
    Suitcase = 'icon-uniE60E',
    SuitcaseCircle = 'icon-suitcase',
    TaOwlCircle = 'icon-icon_reviews',
    ThumbsUp = 'icon-uniE60C',
    TrashCan = 'icon-trashcan',
    TriangleDown = 'icon-uniE600',
    TriangleLeft = 'icon-uniE601',
    TriangleRight = 'icon-uniE6022',
    TriangleUp = 'icon-uniE6032',
    Twitter = 'icon-twitter',
    YouTube = 'icon-icon_play'
}

interface AwdIconProps {
    name: AwdIconName;
}

const AwdIcon = (props: AwdIconProps) => <span className={`awd-icon ${props.name}`} />;

export default AwdIcon;
