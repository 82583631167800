import React, { ReactNode } from 'react';

export interface OutsideClickHandlerProps {
    children: ReactNode;
    classNameStr?: string; // class name string to apply to the wrapper element
    onOutsideClick: (e: Event) => void;
}

/**
 * Wrapper component that detects clicks outside itself. On outside click, it fires a callback function. This enables
 * taking actions such as closing a modal component when a user clicks outside of it.
 *
 * @see https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
 */
export default class OutsideClickHandler extends React.Component<OutsideClickHandlerProps> {
    private childNode: HTMLDivElement | null;

    constructor(props: OutsideClickHandlerProps) {
        super(props);
        this.childNode = null;
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public render() {
        return (
            <div className={this.props.classNameStr} ref={this.setChildNodeRef}>
                {this.props.children}
            </div>
        );
    }

    private setChildNodeRef = (ref: HTMLDivElement) => {
        this.childNode = ref;
    };

    private handleClickOutside = (e: Event) => {
        if (this.childNode && e.target && !this.childNode.contains(e.target as Node)) {
            this.props.onOutsideClick(e);
        }
    };
}
