import { defineMessages } from 'react-intl';

export default defineMessages({
    cta: {
        id: 'RecentSearchWide_cta',
        defaultMessage: 'Your recently searched trip:'
    },
    locations: {
        id: 'RecentSearchWide_locations',
        defaultMessage: '{origin} to {destination}'
    },
    searchButton: {
        id: 'RecentSearchWide_seeDeal',
        defaultMessage: "See Today's Deal"
    }
});
