import { AwdIconName } from 'components/AwdIcon';

/**
 * Grab style adjustments for size and placement of an input icon. Needed to make up
 * for the fact that our icons vary widely in size. This way we can still ensure some
 * consistency in the space they occupy while allowing the users of an input component
 * to declaratively specify the icon and not worry about sizing
 *
 * @param iconName
 */
export function getIconAdjustments({ iconName }: { iconName: AwdIconName }) {
    switch (iconName) {
        case AwdIconName.PlaneUnderlinedUp:
        case AwdIconName.PlaneUnderlinedDown:
            return { fontSize: '1.6em', bottom: '4px', marginLeft: '3px' };
    }
    return {};
}
