import { Dispatch } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ActionType, createAction, getType } from 'typesafe-actions';
import { Subscriber, Subscription, SubscriptionWithSubType, UpdateSubscriberResponse } from 'models/Subscriptions';
import { patchSubscriber, postSubscriber } from 'api/subscriber';
import { SUBSCRIPTION_TYPES } from 'constants/subscriptions';
import { CurrencyCode } from 'models/Currency';
import Airport from 'models/Airport';
import { setCurrencyCookie } from 'utils/currency';
import { AirportSuggestion } from 'models/Suggestion';
import { Site } from 'models/Sites';
import { getSite, StoreWithConfigState } from 'ducks/config';
import * as Sentry from '@sentry/browser';

export type UserState = Readonly<{
    currency?: CurrencyCode;
    subscriber?: Subscriber;
    homeAirport?: Airport;
}>;

export interface StoreWithUserState {
    user: UserState;
}

type StoreWithUserAndConfigState = StoreWithUserState & StoreWithConfigState;

// An event fired so FlightJS code can fire off tracking calls for subscriptions
const EV_DATA_SUBS_REACT_SUBSCRIBER_RESPONSE = 'data:subs:react:subscriber:response';

// Define actions:
const userActions = {
    /**
     * "queues" a new subscriber to be sent to the subscription service, but does not send it
     * yet. We use this queueing functionality so that all the forms on a given page will show
     * the same email when a user updates it in one form. That email will then subsequently be
     * sent to the subscription service using the {@link #addOrUpdateAwdSubscriber} action
     */
    queueNewSubscriber: createAction('awd/user/QUEUE_NEW_SUBSCRIBER', resolve => {
        return (subscriber: Subscriber) => {
            return resolve({ subscriber });
        };
    }),
    /**
     * Action dispatched when a request to create or update an AWD Subscriber is resolved successfully
     */
    writeAwdSubscriberSuccess: createAction('awd/user/WRITE_AWD_SUBSCRIBER_SUCCESS', resolve => {
        /**
         * @param subscriber - subscriber object returned from the server
         * @param homeAirport - home airport object returned from the server
         * @param existingCurrency - Currency currently stored in the redux store
         */
        return ({
            subscriber,
            homeAirport,
            existingCurrency
        }: {
            subscriber: Subscriber;
            homeAirport: Airport;
            existingCurrency?: CurrencyCode;
        }) => {
            return resolve({
                subscriber,
                homeAirport,
                currency: (subscriber.preferences && subscriber.preferences.currency) || existingCurrency
            });
        };
    }),
    /**
     * Action dispatched when a request to create or update a Subscriber for one of our other sites is resolved
     * successfully.
     */
    writeCoregSubscriberSuccess: createAction('awd/user/WRITE_COREG_SUBSCRIBER_SUCCESS', resolve => {
        return ({
            subscriber,
            homeAirport,
            existingCurrency
        }: {
            subscriber: Subscriber;
            homeAirport: Airport;
            existingCurrency?: CurrencyCode;
        }) => {
            return resolve({
                subscriber,
                homeAirport,
                currency: (subscriber.preferences && subscriber.preferences.currency) || existingCurrency
            });
        };
    }),
    /**
     * Action dispatched when a request to create or update a Subscriber fails
     */
    writeSubscriberFailure: createAction('awd/user/WRITE_SUBSCRIBER_FAILURE', () => {
        return (e: Error) => {
            throw e;
        };
    }),
    updateCurrency: createAction('awd/user/UPDATE_CURRENCY', resolve => {
        return ({ currency }: { currency: CurrencyCode }) => {
            return resolve({ currency });
        };
    })
};

export type UserAction = ActionType<typeof userActions>;

// Reducer:
export default function reducer(state: UserState = {}, action: UserAction): UserState {
    switch (action.type) {
        case getType(userActions.updateCurrency):
        case getType(userActions.queueNewSubscriber):
        case getType(userActions.writeAwdSubscriberSuccess):
            return { ...state, ...action.payload };
        // No need to overwrite our info for an AWD subscriber with the same subscriber on other sites:
        case getType(userActions.writeCoregSubscriberSuccess):
        default:
            return state;
    }
}

// Selectors:
export const getSubscriber = (state: StoreWithUserState): Subscriber | undefined => state.user.subscriber;
export const getHomeAirport = (state: StoreWithUserState): Airport | undefined => state.user.homeAirport;
export const getCurrency = (state: StoreWithUserState): CurrencyCode => state.user.currency!;

/**
 * Checks if a user is currently logged into the site. This looks for a tracking code here
 * because a user who has entered a value into an email input but not yet subscribed
 * (a "queued" subscriber) will have an email address in state, but no tracking code.
 */
export function isUserLoggedIn(state: StoreWithUserState): boolean {
    const { subscriber } = state.user;
    return !!(subscriber && subscriber.trackingCode);
}

// Action Creators:
export function queueNewSubscriber(subscriber: Subscriber): UserAction {
    return userActions.queueNewSubscriber(subscriber);
}

export function addOrUpdateAwdSubscriber({
    subscriber
}: {
    subscriber: Subscriber;
}): ThunkAction<Promise<void>, StoreWithUserAndConfigState, void, UserAction> {
    return async (
        dispatch: ThunkDispatch<StoreWithUserAndConfigState, void, UserAction>,
        getState: () => StoreWithUserAndConfigState
    ) => {
        return dispatch(
            addOrUpdateSubscriberBySite({
                subscriber,
                subscriptionsBySite: { [getSite(getState())]: subscriber.subscriptions }
            })
        );
    };
}

export function addOrUpdateSubscriberBySite({
    subscriber,
    subscriptionsBySite
}: {
    subscriber: Subscriber;
    subscriptionsBySite: { [key in Site]?: Subscription[] };
}): ThunkAction<Promise<void>, StoreWithUserAndConfigState, void, UserAction> {
    return async (dispatch: Dispatch<UserAction>, getState: () => StoreWithUserAndConfigState) => {
        const existingCurrency = getCurrency(getState()),
            sites = Object.keys(subscriptionsBySite) as Site[],
            postSubscriberToSite = (site: Site) =>
                postSubscriber({ subscriber: { ...subscriber, subscriptions: subscriptionsBySite[site] || [] }, site })
                    .then(res => {
                        trackSubscriptions(res);
                        const { subscriber, homeAirport } = res,
                            writeAction = [Site.AWD, Site.AWD_UK].includes(site)
                                ? userActions.writeAwdSubscriberSuccess
                                : userActions.writeCoregSubscriberSuccess;
                        dispatch(writeAction({ subscriber, homeAirport, existingCurrency }));
                    })
                    .catch(e => dispatch(userActions.writeSubscriberFailure(e)));

        // TODO: CRM asked us to throttle requests that might create new users until they complete this ticket:
        // https://jira.smartertravelmedia.com/browse/CRMP-388. Thus the code below chains promises to post
        // subscribers to the various sites sequentially. Once that's done we should modify this section of
        // code to fire off these requests in parallel
        return sites.reduce<Promise<void>>((previousPromise, currentSite) => {
            return previousPromise.then(
                () => postSubscriberToSite(currentSite),
                e => {
                    Sentry.captureException(e);
                    // eslint-disable-next-line no-console
                    console.error(e);
                    return postSubscriberToSite(currentSite);
                }
            );
        }, Promise.resolve());
    };
}

export function updateCurrency({
    currency
}: {
    currency: CurrencyCode;
}): ThunkAction<Promise<void>, StoreWithUserAndConfigState, void, UserAction> {
    return async (dispatch: Dispatch<UserAction>, getState: () => StoreWithUserAndConfigState) => {
        const subscriber = getSubscriber(getState()),
            site = getSite(getState());

        setCurrencyCookie({ currency });
        dispatch(userActions.updateCurrency({ currency }));

        // If the subscriber hasn't been created yet, we just update the currency cookie:
        if (!subscriber || !subscriber.trackingCode) {
            return Promise.resolve();
        }

        const preferences = { ...subscriber.preferences, currency },
            subscriberPatch = { ...subscriber, preferences };

        return patchSubscriber({ subscriber: subscriberPatch, site })
            .then(res => {
                const { subscriber, homeAirport } = res;
                dispatch(
                    userActions.writeAwdSubscriberSuccess({ subscriber, homeAirport, existingCurrency: currency })
                );
            })
            .catch(e => dispatch(userActions.writeSubscriberFailure(e)));
    };
}

export function updateHomeAirport({
    homeAirport
}: {
    homeAirport: AirportSuggestion;
}): ThunkAction<Promise<UserAction>, StoreWithUserAndConfigState, void, UserAction> {
    return async (dispatch: Dispatch<UserAction>, getState: () => StoreWithUserAndConfigState) => {
        const subscriber = getSubscriber(getState()),
            existingCurrency = getCurrency(getState()),
            site = getSite(getState());

        // If we don't have a subscriber, we can't update the home airport
        if (!subscriber || !subscriber.trackingCode) {
            return Promise.reject("Can't update home airport without an active subscriber");
        }

        return patchSubscriber({
            subscriber: { ...subscriber, homeAirport: homeAirport.airportCode },
            site
        })
            .then(res => {
                const { subscriber, homeAirport } = res;
                return dispatch(userActions.writeAwdSubscriberSuccess({ subscriber, homeAirport, existingCurrency }));
            })
            .catch(e => dispatch(userActions.writeSubscriberFailure(e)));
    };
}

/**
 * Fires an event with data about the subscriber. FlightJS listens for the event and fires off the appropriate
 * tracking calls (e.g. collection service, GTM).
 */
function trackSubscriptions(subscriberData: UpdateSubscriberResponse) {
    // subscriptionType is used to determine which evars and product names should be sent
    const newSubscriptionsWithSubTypes: SubscriptionWithSubType[] = subscriberData.newSubscriptions.map(
        (sub: Subscription) => ({
            ...sub,
            subscriptionType: SUBSCRIPTION_TYPES[sub.productId]
        })
    );

    // initCustomEvent is deprecated, but IE11 doesn't support `new CustomEvent()`.
    const subscriberResponseEvent: CustomEvent = document.createEvent('CustomEvent');
    subscriberResponseEvent.initCustomEvent(EV_DATA_SUBS_REACT_SUBSCRIBER_RESPONSE, true, true, {
        ...subscriberData,
        newSubscriptions: newSubscriptionsWithSubTypes
    });
    document.dispatchEvent(subscriberResponseEvent);
}
