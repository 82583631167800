export enum CookieLocationType {
    AIRPORT = 'airport',
    GEO = 'geo'
}

export interface CookieLocation {
    type: CookieLocationType;
    id: number;
    cid: number;
    aid?: number;
}

export default interface CookieSearchData {
    date1?: number;
    date2?: number;
    defaultDates?: boolean;
    location1?: CookieLocation;
    location2?: CookieLocation;
    nonStop?: 0 | 1;
    oneway?: 0 | 1;
    travelers?: number;
}
