/**
 * Front end representation of the FlightServiceClass.java enum
 */
enum FlightServiceClass {
    ECONOMY_COACH = 'economy_coach',
    BUSINESS = 'business',
    FIRST_CLASS = 'first_class'
}

export default FlightServiceClass;
