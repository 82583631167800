/**
 * Subscription frequencies supported by CRM
 */
enum SubscriptionFrequency {
    Daily = 'daily',
    Frequent = 'frequent',
    Weekly = 'weekly'
}

export default SubscriptionFrequency;
