export const PATH_CATEGORIES_HOME = '/discover-flight-deals/';
export const PATH_CATEGORIES_LIST_ENDPOINT = '/categories/json/';
export const PATH_WEEKENDER = '/weekender/';
export const PATH_TOP_50_FARES = '/top-50-fares/';
export const PATH_PRIVACY_STATEMENT = 'https://www.smartertravel.com/privacy-policy/';
export const PATH_ABOUT_US = '/about-us/';
export const PATH_CONTACT_AWD =
    'https://smartertravelmedia.zendesk.com/hc/en-us/requests/new?ticket_form_id=4417063557652';
export const PATH_TERMS_OF_USE = '/terms-of-use/';
export const PATH_FARE_ALERTS_PREFERENCES = '/fare-alerts/preferences/';
export const PATH_COOKIE_POLICY = '/cookie-policy/';
