// @ts-nocheck - stops IntelliJ from complaining about nonexistent properties
const browserSupportsAllFeatures = () => {
    return (
        window.Promise &&
        window.fetch &&
        window.Intl &&
        window.Object.assign &&
        window.Object.values &&
        window.Object.keys &&
        window.String.prototype.padStart &&
        window.Array.prototype.includes &&
        window.Array.prototype.forEach
    );
};

const loadScript = (src: string, callback: () => void) => {
    const js = document.createElement('script');
    js.src = src;
    js.onload = () => callback();
    js.onerror = () => {
        // TODO: If we fail to load the polyfill, we should make sure it gets reported somewhere (Sentry? see AWD-2916)
        // How should we handle this case? Should we attempt to call the onLoad callback anyway, or just error and die here?
        throw new Error('Failed to load polyfill: ' + src);
    };
    document.head && document.head.appendChild(js);
};

/**
 * Conditionally loads the polyfill bundle based on features present in the browser.
 * If any required feature is missing, the polyfill will be loaded.
 * @see https://philipwalton.com/articles/loading-polyfills-only-when-needed/
 *
 * @param staticBaseUrl - e.g. /static/v3.0.97-SNAPSHOT/
 * @param callback - main function to start the app after polyfill is loaded
 */
export function loadPolyfill(staticBaseUrl: string, callback: () => void) {
    if (!browserSupportsAllFeatures()) {
        loadScript(staticBaseUrl + 'react/polyfill.bundle.js', callback);
    } else {
        callback();
    }
}
