import { defineMessages } from 'react-intl';
import { MSG_SUBSCRIBE_DISCLAIMER } from 'constants/sharedMessages';

export default defineMessages({
    signupLabel: {
        id: 'Footer_signupLabel',
        defaultMessage: 'Get Travel Updates'
    },
    signup: {
        id: 'Footer_signup',
        defaultMessage: 'Sign Up'
    },
    signedup: {
        id: 'Footer_signedup',
        defaultMessage: 'Thanks For Signing Up!'
    },
    emailPlaceholder: {
        id: 'Footer_emailPlaceholder',
        defaultMessage: 'Enter Email Address'
    },
    about: {
        id: 'Footer_about',
        defaultMessage: 'About Us'
    },
    contact: {
        id: 'Footer_contact',
        defaultMessage: 'Contact Us'
    },
    privacy: {
        id: 'Footer_privacy',
        defaultMessage: 'Privacy and Cookies Statement'
    },
    terms: {
        id: 'Footer_terms',
        defaultMessage: 'Terms of Use'
    },
    preferences: {
        id: 'Footer_preferences',
        defaultMessage: 'Update Preferences'
    },
    visitUS: {
        id: 'Footer_visitUS',
        defaultMessage: 'View US Site Experience'
    },
    visitUK: {
        id: 'Footer_visitUK',
        defaultMessage: 'View UK Site Experience'
    },
    cookie: {
        id: 'Footer_cookie',
        defaultMessage: 'Cookie Policy'
    },
    consent: {
        id: 'Footer_consent',
        defaultMessage: 'Cookie Consent'
    },
    legal: {
        id: 'Footer_legal',
        defaultMessage: '\u00a9 1998-{date} Smarter Travel Media LLC.'
    },
    rights: {
        id: 'Footer_rights',
        defaultMessage: 'All Rights Reserved'
    },
    subscribeDisclaimer: {
        id: 'Footer_subscribeDisclaimer',
        defaultMessage: MSG_SUBSCRIBE_DISCLAIMER
    }
});
