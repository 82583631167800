import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import Spinner, { SpinnerColor } from 'components/Spinner';

interface ButtonProps {
    text: FormattedMessage.MessageDescriptor;
    onClick?: ({}) => void;
    showSpinner?: boolean;
    textFontSize?: string;
}

export default class Button extends React.Component<ButtonProps> {
    public render() {
        const { text, onClick, showSpinner, textFontSize } = this.props;

        return (
            <button className='button-awd' onClick={onClick}>
                <span className='button-awd__text' style={{ fontSize: textFontSize || '1em' }}>
                    {showSpinner ? <Spinner color={SpinnerColor.White} /> : <FormattedMessage {...text} />}
                </span>
            </button>
        );
    }
}
