import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import Button from 'components/Button';
import { DateFormat } from 'constants/date-formats';
import { Suggestion } from 'models/Suggestion';
import { RecentSearchProps } from '../shared/props';
import msgs from './messages';
import './styles.scss';
import OutsideClickHandler from 'components/OutsideClickHandler';

interface RecentSearchCollapsibleProps<T extends Suggestion> extends RecentSearchProps<T> {
    enablePageMask?: boolean;
}

export default function RecentSearchCollapsible<T extends Suggestion>({
    searchData,
    onClick,
    enablePageMask = true
}: RecentSearchCollapsibleProps<T>) {
    const [isCollapsed, setCollapsed] = useState(true),
        { location1, location2, date1, date2 } = searchData;

    return (
        <div className='recent-search__container recent-search__container--collapsible do_no_popunder'>
            {enablePageMask && !isCollapsed && <div className='recent-search__mask' />}
            <div className='recent-search recent-search--collapsible'>
                <OutsideClickHandler onOutsideClick={() => setCollapsed(true)}>
                    <div className='recent-search__cta-section' onClick={() => setCollapsed(!isCollapsed)}>
                        <div className='recent-search__icon-left'>
                            <AwdIcon name={AwdIconName.Refresh} />
                        </div>
                        <div className='recent-search__cta'>
                            <FormattedMessage {...msgs.cta} />
                        </div>
                        <div className='recent-search__icon-right'>
                            <AwdIcon name={AwdIconName.ArrowDown} />
                        </div>
                    </div>
                    <div
                        className={`recent-search__content ${
                            isCollapsed ? 'recent-search__content--collapsed' : 'recent-search__content--expanded'
                        }`}
                    >
                        <div className='recent-search__info-section'>
                            <div className='recent-search__locations'>
                                <span className='recent-search__location'>
                                    <span>{location1.text}</span>
                                </span>
                                <span className='recent-search__location'>
                                    <span className='recent-search__location-separator'>
                                        <FormattedMessage {...msgs.to} />
                                    </span>
                                    <span>{location2.text}</span>
                                </span>
                            </div>
                            {date1 && date2 && (
                                <div className='recent-search__dates'>
                                    <span>
                                        {date1.format(DateFormat.monthDayText)} -{' '}
                                        {date2.format(DateFormat.monthDayText)}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className='recent-search__search-section'>
                            <div className='recent-search__button'>
                                <Button text={msgs.searchButton} onClick={onClick} />
                            </div>
                        </div>
                    </div>
                </OutsideClickHandler>
            </div>
        </div>
    );
}
