import { NewsletterProductId } from 'models/Subscriptions';
import { Site } from 'models/Sites';

/**
 * A mapping of NewsletterProductId to subscription type string, mainly for the purpose of communicating with
 * FlightJS code for tracking. Note that AWDUK subscriptions have the same value as their US counterparts: they're
 * communicated to the Collection Service, etc. the same way.
 *
 * @see https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/webapp/js/src/desktop/modules/flight-subs/lib/data/subTypeConstants.js
 */
export const SUBSCRIPTION_TYPES: { [key: number]: string } = {
    [NewsletterProductId.AWD_ARRIVAL]: 'arrival',
    [NewsletterProductId.AWD_CITY_TO_CITY]: 'city_to_city',
    [NewsletterProductId.AWD_DEPARTURE]: 'departure',
    [NewsletterProductId.AWD_INSIDER]: 'airfare_insider',
    [NewsletterProductId.AWD_SPECIAL_OFFER]: 'awd_special_offer',
    [NewsletterProductId.AWD_UPDATES]: 'airfarewatchdog',
    [NewsletterProductId.AWDUK_CITY_TO_CITY]: 'city_to_city',
    [NewsletterProductId.AWDUK_DEPARTURE]: 'departure',
    [NewsletterProductId.AWDUK_HWD_CITY]: 'hwd_city',
    [NewsletterProductId.AWDUK_TRIPWATCH]: 'tripwatch',
    [NewsletterProductId.AWDUK_UPDATES]: 'airfarewatchdog',
    [NewsletterProductId.FVC_INSIDER]: 'fvc_insider',
    [NewsletterProductId.FVC_DEALS]: 'fvc_deals',
    [NewsletterProductId.FVC_MEMBER_OFFER]: 'fvc_member_offer',
    [NewsletterProductId.FVC_UPDATES]: 'fvc_updates',
    [NewsletterProductId.HWD_CITY]: 'hwd_city',
    [NewsletterProductId.HWD_UPDATES]: 'hotelwatchdog',
    [NewsletterProductId.JS_DEALS]: 'js_deals',
    [NewsletterProductId.JS_GENERAL]: 'js_general',
    [NewsletterProductId.JS_INSPIRATION]: 'js_inspiration',
    [NewsletterProductId.OYS_GENERAL]: 'oys_general',
    [NewsletterProductId.OYS_HOTEL_DEALS]: 'oys_hotel_deals',
    [NewsletterProductId.OYS_UPDATES]: 'oys_updates',
    [NewsletterProductId.ST_DEAL_ALERT]: 'st_deal_alert',
    [NewsletterProductId.ST_SPOTLIGHT]: 'st_spotlight',
    [NewsletterProductId.ST_TRIP_REMINDER]: 'st_trip_reminder',
    [NewsletterProductId.ST_UPDATES]: 'st_updates',
    [NewsletterProductId.TRIPWATCH]: 'tripwatch',
    [NewsletterProductId.WEEKENDER]: 'weekender'
};

export const SITE_TO_NL_PRODUCT_IDS = {
    [Site.AWD]: [
        NewsletterProductId.AWD_DEPARTURE,
        NewsletterProductId.AWD_ARRIVAL,
        NewsletterProductId.AWD_CITY_TO_CITY,
        NewsletterProductId.AWD_UPDATES,
        NewsletterProductId.AWD_INSIDER,
        NewsletterProductId.HWD_UPDATES,
        NewsletterProductId.TRIPWATCH,
        NewsletterProductId.WEEKENDER,
        NewsletterProductId.AWD_SPECIAL_OFFER,
        NewsletterProductId.HWD_CITY
    ],
    [Site.AWD_UK]: [
        NewsletterProductId.AWDUK_DEPARTURE,
        NewsletterProductId.AWDUK_CITY_TO_CITY,
        NewsletterProductId.AWDUK_HWD_CITY,
        NewsletterProductId.AWDUK_TRIPWATCH,
        NewsletterProductId.AWDUK_UPDATES
    ],
    [Site.JS]: [NewsletterProductId.JS_GENERAL, NewsletterProductId.JS_INSPIRATION, NewsletterProductId.JS_DEALS],
    [Site.ST]: [
        NewsletterProductId.ST_UPDATES,
        NewsletterProductId.ST_TRIP_REMINDER,
        NewsletterProductId.ST_DEAL_ALERT,
        NewsletterProductId.ST_SPOTLIGHT
    ],
    [Site.FVC]: [
        NewsletterProductId.FVC_INSIDER,
        NewsletterProductId.FVC_DEALS,
        NewsletterProductId.FVC_MEMBER_OFFER,
        NewsletterProductId.FVC_UPDATES
    ],
    [Site.OYS]: [NewsletterProductId.OYS_GENERAL, NewsletterProductId.OYS_HOTEL_DEALS, NewsletterProductId.OYS_UPDATES]
};
