/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/java/com/smartertravel/awd/spring/SentryConfig.java
 */
export default interface SentryConfig {
    dsnUrl: string;
    enabled: boolean;
    environment: SentryEnvironment;
    release: string;
}

/**
 * Sentry environment values corresponding to the "sentry.environment" property in application*.properties files.
 */
export enum SentryEnvironment {
    DEVELOPMENT = 'development',
    QA = 'qa',
    STAGING = 'staging',
    PRODUCTION = 'production'
}
