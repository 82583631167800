import { AwdSite, AwdSiteId, Site, SiteId } from 'models/Sites';

export function getSiteFromSiteId(siteId: AwdSiteId): AwdSite;
export function getSiteFromSiteId(siteId: SiteId) {
    switch (siteId) {
        case SiteId.AWD:
            return Site.AWD;
        case SiteId.AWD_UK:
            return Site.AWD_UK;
        case SiteId.FVC:
            return Site.FVC;
        case SiteId.JS:
            return Site.JS;
        case SiteId.OYS:
            return Site.OYS;
        case SiteId.ST:
            return Site.ST;
    }
}
