import React from 'react';
import ReactDOM from 'react-dom';
import { AnyAction, Store } from 'redux';

import { StoreWithUserState } from 'ducks/user';
import { getSiteId, StoreWithConfigState } from 'ducks/config';
import AwdProvider from 'components/AwdProvider';
import Footer from 'components/Footer';

export function renderFooter({ store }: { store: Store<StoreWithUserState & StoreWithConfigState, AnyAction> }) {
    ReactDOM.render(
        <AwdProvider store={store}>
            <Footer siteId={getSiteId(store.getState())} />
        </AwdProvider>,
        document.getElementById('react-root__footer') as HTMLElement
    );
}
