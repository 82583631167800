import Cookies from 'js-cookie';
import { getBaseDomain } from './urls';

/**
 * Sets a cookie with the specified name and value.
 *
 * @param name
 * @param value
 * @param expireInDays
 */
export function setCookie({ name, value, expireInDays }: { name: string; value: string; expireInDays: number }): void {
    Cookies.set(name, value, {
        expires: expireInDays,
        path: '/',
        secure: !window.location.hostname.startsWith('localhost'),
        domain: getBaseDomain()
    });
}

/**
 * Gets the value of the cookie matching the specified name
 *
 * @param name
 */
export function getCookie(name: string): string | undefined {
    return Cookies.get(name);
}
