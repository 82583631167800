export enum Language {
    EN = 'en'
}

export enum CountryCode {
    US = 'US',
    GB = 'GB',
    CA = 'CA'
}

export enum LanguageTag {
    enUS = 'en-US',
    enGB = 'en-GB',
    enCA = 'en-CA'
}

/**
 * Locale options supported by AWD
 */
export class Locale {
    public static readonly EnglishUS = new Locale(Language.EN, CountryCode.US, LanguageTag.enUS);
    public static readonly EnglishUK = new Locale(Language.EN, CountryCode.GB, LanguageTag.enGB);

    private constructor(readonly language: Language, readonly country: CountryCode, readonly languageTag: LanguageTag) {
        this.language = language;
        this.country = country;
        this.languageTag = languageTag;
    }

    public equals(obj: Locale) {
        return this.language === obj.language && this.country === obj.country && this.languageTag === obj.languageTag;
    }
}
