import { URLSearchParams } from 'url';

const getJsonData = async <T>(response: Response): Promise<T> => {
    if (!response.ok) {
        throw Error(response.statusText);
    }

    try {
        return await response.json();
    } catch (error) {
        throw Error('failed to json');
    }
};

const get = async <T>(url: string): Promise<T> => {
    const resp = await fetch(url, { method: 'GET' });
    return getJsonData(resp);
};

const post = async <T, U>(url: string, body: U, contentType: string, acceptType?: string): Promise<T | void> => {
    let payload;

    if (contentType === 'application/x-www-form-urlencoded') {
        payload = (body as unknown) as URLSearchParams;
    } else if (contentType === 'multipart/form-data') {
        payload = (body as unknown) as FormData;
    } else {
        payload = JSON.stringify(body);
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': contentType
            },
            body: payload
        });

        if (acceptType === 'application/json') {
            const jsonData: T = await getJsonData(response);
            return Promise.resolve(jsonData);
        } else {
            return Promise.resolve();
        }
    } catch (err) {
        return Promise.reject(err);
    }
};

const APIService = {
    get,
    post
};

export default APIService;
