import { applyMiddleware, compose, createStore, combineReducers } from 'redux';

import userReducer from 'ducks/user';
import { getGlobalMiddlewares } from 'utils/app';
import { AwdWindow } from 'models/AwdWindow';
import configReducer from 'ducks/config';
import { renderBaseComponents } from 'apps/common';

// Global styles are normally imported once at the page level, but since this app renders multiple individual
// components, we'll import it here instead.
import 'styles/global.scss';

declare const window: AwdWindow;

/**
 * Basic app used by all "non-react" pages to render any react components that show up on all pages
 * like the user preferences picker.
 */
export function start() {
    const { subscriber, currency, homeAirport, staticBaseUrl, abTests, locale, siteId } = window.pageData,
        composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose,
        reducers = combineReducers({ config: configReducer, user: userReducer }),
        store = createStore(
            reducers,
            {
                config: { staticBaseUrl, abTests, locale, siteId },
                user: { subscriber, currency, homeAirport }
            },
            composeWithDevTools(applyMiddleware(...getGlobalMiddlewares(window.pageData)))
        );

    renderBaseComponents({ store, reloadPageOnCurrencyChange: true });
}
