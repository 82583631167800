import React, { ReactNode } from 'react';

import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';

export enum CheckboxTheme {
    Default = 'default',
    Blue = 'blue',
    FlatCircle = 'flat-circle'
}

interface CheckboxProps {
    children: ReactNode;
    checked: boolean;
    theme?: CheckboxTheme;
    onChange?(checked: boolean): void;
    onClick?(): void;
}

export default function Checkbox({ checked, theme, onChange, onClick, children }: CheckboxProps) {
    return (
        <label className={`awd-checkbox awd-checkbox--${theme}  do_no_popunder`}>
            <span className={'awd-checkbox__label'}>{children}</span>
            <input
                className='awd-checkbox__input'
                type='checkbox'
                checked={checked}
                onChange={() => onChange && onChange(!checked)}
                onClick={e => {
                    onClick && onClick();

                    // Weird behavior of inputs associated with labels: when the label element is clicked,
                    // browsers pass the event to the associated input. The problem is that then both events
                    // will propagate up the DOM. So if we have any events attached to parent elements they
                    // end up being called twice for the same event, which is a bit crazy-making when you first
                    // see it. See: https://stackoverflow.com/a/24501565 To preserve our sanity, let's just
                    // stop propagation here:
                    e.stopPropagation();
                }}
            />
            <span className={`awd-checkbox__checkbox ${checked ? 'awd-checkbox__checkbox--checked' : ''}`}>
                <span className='awd-checkbox__check'>
                    <AwdIcon name={AwdIconName.CheckCursive} />
                </span>
            </span>
        </label>
    );
}
