/**
 * Valid value for an input's autocomplete tag. With the exception of "off" (see next paragraph)
 * any values added to this field should match values from the spec as listed here:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
 *
 * autocomplete="off" is different. Chrome (and maybe other browsers) won't always respect it.
 * See here for some: https://www.codementor.io/@leonardofaria/disabling-autofill-in-chrome-zec47xcui
 * In my testing, I've seen that chrome will sometimes respect the autocomplete="off" attribute and
 * other times it won't. For example, in one of our widgets Chrome was auto-populating the user's home
 * city in our destination cities but only when the placeholder value included the word "City". When we
 * used something like "Anywhere (optional)" it did not autocomplete. But if we set an arbitrary string in
 * the autocomplete attribute, Chrome appears to assume that string matches a valid field, one it doesn't
 * know about, and it won't autocomplete or attempt to infer the type of data that should go in the
 * input from the placeholder field (at least as of 2/2020). A lot of SO answers suggest using
 * autocomplete="nope" to disable autocomplete which is cute but I wouldn't be surprised if
 * Chrome got wise to that, so we should explicit set it to something less generic below.
 *
 * Whatever you do, if you change the value for OFF please be sure to test thoroughly
 * across devices. When browsers start auto-completing our destination fields, we see substantial
 * declines in search rates
 */
enum Autocomplete {
    OFF = '__disabled-search', // Chrome does not _always_ respect autocomplete="off" value. See above
    // Other values besides OFF should match values in https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
    EMAIL = 'email'
}

export default Autocomplete;
