import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Store, AnyAction } from 'redux';
import { AwdWindow } from 'models/AwdWindow';
import { getSubscriber, StoreWithUserState } from 'ducks/user';
import { StoreWithConfigState } from 'ducks/config';
import AwdProvider from 'components/AwdProvider';

declare const window: AwdWindow;

function reloadPage() {
    window.location.reload();
}

function renderDesktopUserPreferencesMenu({
    store,
    reloadPageOnCurrencyChange
}: {
    store: Store<StoreWithUserState & StoreWithConfigState, AnyAction>;
    reloadPageOnCurrencyChange?: boolean;
}) {
    const ConnectedUserPreferencesNavDropdown = lazy(() => import('components/UserPreferencesNavDropdown/connected'));

    ReactDOM.render(
        <Suspense fallback={null}>
            <AwdProvider store={store}>
                <ConnectedUserPreferencesNavDropdown
                    onCurrencyChange={reloadPageOnCurrencyChange ? reloadPage : undefined}
                />
            </AwdProvider>
        </Suspense>,
        document.getElementById('react-root__desktop-user-preferences') as HTMLElement
    );
}

function renderMobileHomeAirportPicker({ store }: { store: Store<StoreWithUserState, AnyAction> }) {
    if (!getSubscriber(store.getState())) {
        return;
    }

    const ConnectedHomeAirportPicker = lazy(() => import('components/HomeAirportPicker/connected'));

    ReactDOM.render(
        <Suspense fallback={null}>
            <AwdProvider store={store}>
                <ConnectedHomeAirportPicker />
            </AwdProvider>
        </Suspense>,
        document.getElementById('react-root__mobile-home-airport-picker') as HTMLElement
    );
}

function renderMobileCurrencyPicker({
    store,
    reloadPageOnCurrencyChange
}: {
    store: Store<StoreWithUserState, AnyAction>;
    reloadPageOnCurrencyChange?: boolean;
}) {
    const ConnectedCurrencyPicker = lazy(() => import('components/CurrencyPicker/connected'));

    ReactDOM.render(
        <Suspense fallback={null}>
            <AwdProvider store={store}>
                <ConnectedCurrencyPicker onCurrencyChange={reloadPageOnCurrencyChange ? reloadPage : undefined} />
            </AwdProvider>
        </Suspense>,
        document.getElementById('react-root__mobile-currency-picker') as HTMLElement
    );
}

export default function renderUserPreferencesMenus({
    store,
    reloadPageOnCurrencyChange
}: {
    store: Store<StoreWithUserState & StoreWithConfigState, AnyAction>;
    reloadPageOnCurrencyChange?: boolean;
}) {
    renderDesktopUserPreferencesMenu({ store, reloadPageOnCurrencyChange });
    renderMobileHomeAirportPicker({ store });
    renderMobileCurrencyPicker({ store, reloadPageOnCurrencyChange });
}
