import { AwdWindow } from 'models/AwdWindow';
import APIService from './apiService';

declare const window: AwdWindow;

let seens: Record<string, string>;

const appendExperimentSeen = (name: string, variant: boolean) => {
    if (seens === undefined) {
        seens = {};
    }

    seens[name] = variant ? 'b' : 'a';

    setTimeout(() => {
        sendExperimentSeens();
    }, 1000);
};

const sendExperimentSeens = () => {
    if (seens !== undefined && Object.keys(seens).length !== 0) {
        APIService.post<void, Record<string, string>>('/api/client-event/experimentSeen', seens, 'application/json');
        seens = {};
    }
};

export function determineExperiment(expName: string): boolean | undefined {
    if (window.experiments !== undefined && window.experiments[expName]) {
        const variant = window.experiments[expName];
        appendExperimentSeen(expName, variant);
        return variant;
    }
    return undefined;
}
