// Breakpoint values match those in styles/mixins/response.scss
export const MAX_WIDTH_PHONE = 425;
export const MAX_WIDTH_TABLET = 767;
export const MAX_WIDTH_XS = 767;
export const MAX_WIDTH_SM = 991;
export const MAX_WIDTH_MD = 1199;
export const MAX_WIDTH_LG = 1599;
export const MIN_HEIGHT_XL = 800;

export const mediaXs = { maxWidth: MAX_WIDTH_XS };
export const mediaNotXs = { minWidth: MAX_WIDTH_XS + 1 };
export const mediaLessThanMd = { maxWidth: MAX_WIDTH_SM };
export const mediaGreaterThanSm = { minWidth: MAX_WIDTH_SM + 1 };
export const mediaLessThanLg = { maxWidth: MAX_WIDTH_MD };
export const mediaGreaterThanMd = { minWidth: MAX_WIDTH_MD + 1 };
export const mediaSm = { minWidth: MAX_WIDTH_XS + 1, maxWidth: MAX_WIDTH_SM };
export const mediaMd = { minWidth: MAX_WIDTH_SM + 1, maxWidth: MAX_WIDTH_MD };
export const mediaSmOrMd = { minWidth: MAX_WIDTH_XS + 1, maxWidth: MAX_WIDTH_MD };
export const mediaLg = { minWidth: MAX_WIDTH_MD + 1 };
export const mediaXl = { minWidth: MAX_WIDTH_LG + 1, minHeight: MIN_HEIGHT_XL };
export const mediaPhone = { maxWidth: MAX_WIDTH_PHONE };
export const mediaTablet = { minWidth: MAX_WIDTH_PHONE + 1, maxWidth: MAX_WIDTH_TABLET };
