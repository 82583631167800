import { FareAction, FareAttribute } from 'models/Fares';
import Airline from 'models/Airline';
import { FarePriceHistory } from 'models/Fares/FarePriceDelta';
import { CurrencyCode } from 'models/Currency';
import { FareBlackoutType } from 'models/Fares/FareBlackout';

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/api/src/main/java/com/smartertravel/awd/model/fare/FareListGroup.java
 */
export interface IncomingFareListGroup {
    airportRoute: string;
    arrivalCode: string;
    cityFareAction: FareAction;
    departureCode: string;
    fareRouteUrl: string;
    displayFares: IncomingDisplayFaresWrapper[];
}

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/api/src/main/java/com/smartertravel/awd/model/fare/DisplayFaresWrapper.java
 */
export interface IncomingDisplayFaresWrapper {
    fare: IncomingFare;
    fareInstances: IncomingDisplayFareInstance[];
    arrivalAirportDisplay: string;
    departureAirportDisplay: string;
    currencySymbol: string;
}

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/api/src/main/java/com/smartertravel/awd/model/fare/DisplayFareInstance.java
 */
export interface IncomingDisplayFareInstance {
    airlines: Airline[];
    attributes: FareAttribute[];
    fareInstance: IncomingFareInstance;
    fareUrl: string;
    flexibleDateFare: boolean;
    priceConfidenceAttribute?: FareAttribute;
    tabsUrl?: string;
}

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awd-fare-service-types/browse/src/main/java/com/smartertravel/awd/faresvc/types/Fare.java
 */
export interface IncomingFare {
    airlines: Airline[];
    arrivalCode: string;
    currency: CurrencyCode;
    departureCode: string;
    purchaseBy?: string;
    minStay?: number;
    maxStay?: number;
    advancePurchase?: number;
    blackouts?: IncomingFareBlackout[];
    commentBlock?: string;
    id: number;
    fareAttributes: FareAttribute[];
    price: number;
    prices: { [key in CurrencyCode]: number };
    roundTrip: boolean;
    fareComments?: string[];
}

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awd-fare-service-types/browse/src/main/java/com/smartertravel/awd/faresvc/types/FareInstance.java
 */
export interface IncomingFareInstance {
    airlineIds: number[];
    departureDate: string | null;
    fareAttributeCodes: number[];
    inboundStops: number;
    outboundStops: number;
    returnDate: string | null;
    farePriceHistory?: FarePriceHistory;
}

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awd-fare-service-types/browse/src/main/java/com/smartertravel/awd/faresvc/types/FareBlackout.java
 */
export interface IncomingFareBlackout {
    type: FareBlackoutType;
    blackOutDay?: string;
}

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/api/src/main/java/com/smartertravel/awd/model/fare/FilterableFareDetail.java
 */
export enum IncomingFilterableFareDetail {
    WEEKEND = 'weekend',
    NONSTOP = 'nonStop',
    ONEPLUS_STOPS = 'oneplus_stops',
    NEW = 'new',
    UNADVERTISED = 'unadvertised'
}
