/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awd-fare-service-types/browse/src/main/java/com/smartertravel/awd/faresvc/types/FareAttributeCode.java
 */
enum FareAttributeCode {
    UNKNOWN = -1,
    FLEX = 1,
    THIRTY_DAY = 2,
    WEEKEND = 3,
    MIDWEEK = 4,
    PROMO_CODE = 5,
    EVENT = 6,
    LAST_MINUTE = 7,
    MISTAKE = 8,
    PRICE_DROP = 9,
    UNADVERTISED = 10,
    NEW_NONSTOP = 11,
    AMAZING_DEAL = 12,
    MARKETING_CAMPAIGN = 13,
    SITEDIRECT = 14,
    FARE_SALE = 15,
    TOPDEST = 16,
    TOPROUTE = 17,
    NONSTOP = 18,
    CURATED = 19,
    ENDING_SOON = 20,
    MULTI_DATE = 21,
    WEEKEND_ONE = 22,
    WEEKEND_TWO = 23,
    WEEKEND_THREE = 24,
    BETTER_YOY = 25,
    WORSE_YOY = 26,
    PRICE_LIKELY_TO_INCREASE = 27,
    PRICE_YOY_BETTER_AND_WILL_INCREASE = 28,
    BETTER_WOW = 29,
    WORSE_WOW = 30,
    BETTER_MOM = 31,
    WORSE_MOM = 32
}

export default FareAttributeCode;
