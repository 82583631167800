import { defineMessages } from 'react-intl';

export default defineMessages({
    cta: {
        id: 'TrackDestinationCard_cta',
        defaultMessage: 'Track Prices'
    },
    success: {
        id: 'TrackDestinationCard_success',
        defaultMessage: "You're Signed Up"
    },
    error: {
        id: 'TrackDestinationCard_error',
        defaultMessage: 'Oops. Something went wrong.'
    }
});
