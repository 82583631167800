import React from 'react';
import Autosuggest from 'react-autosuggest';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';

import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import { Suggestion, SuggestionDecorator } from 'models/Suggestion';
import Spinner, { SpinnerColor } from 'components/Spinner';
import { getIconAdjustments } from 'components/Input/common/utils';
import Autocomplete from 'constants/autocomplete';

interface InputWithAutocompleteProps<T extends Suggestion> {
    getSuggestions: (queryText: string) => Promise<Array<SuggestionDecorator<T>>>; // Get suggestions for the provided query
    showSpinnerInsteadOfIcon?: boolean;
    iconName?: AwdIconName;
    iconColor?: string;
    highlightOnEmpty?: boolean;
    minLengthOfQuery?: number; // Minimum number of characters a user has to entry before we show the query suggestions, defaults to 3
    onSuggestionSelected: (suggestion: SuggestionDecorator<T>) => void; // Handler called when a suggestion is selected
    borderColor?: string; // Optional override for the input field's border color
    label?: FormattedMessage.MessageDescriptor; // Label for the the input
    placeholder?: FormattedMessage.MessageDescriptor; // Placeholder text for the the input
    inError?: boolean;
    isClearable?: boolean; // Should the component allow the suggestion value to be cleared?
    suggestion?: SuggestionDecorator<T>;
    onBlur?: (suggestion: SuggestionDecorator<T>) => void; // Function to call on blur, for validation, etc
    onClear?: () => void; // Function to call when the suggestion value is cleared
    intl: InjectedIntl; // Prop automatically injected by injectIntl: https://github.com/yahoo/react-intl/wiki/API#injectintl
    inputRef?: React.RefObject<HTMLInputElement>;
}

interface InputWithAutocompleteState<T extends Suggestion> {
    suggestions: Array<SuggestionDecorator<T>>; // Array containing the suggestions pertaining to the current input value
    value: string; // Current input value;
    suggestionSelected?: SuggestionDecorator<T>;
}

/**
 * Implementation of Input with an auto-suggest type-ahead, typically
 * used for location inputs, etc
 */
class InputWithAutocomplete<T extends Suggestion> extends React.Component<
    InputWithAutocompleteProps<T>,
    InputWithAutocompleteState<T>
> {
    public static defaultProps = { minLengthOfQuery: 3 };

    private isComponentMounted: boolean;
    private readonly randomInt: number;

    constructor(props: InputWithAutocompleteProps<T>) {
        super(props);
        const { suggestion } = this.props;
        this.state = { value: (suggestion && suggestion.text) || '', suggestions: [], suggestionSelected: suggestion };
        this.isComponentMounted = false;
        this.randomInt = Math.floor(Math.random() * 1_000_000_000);
    }

    public render() {
        const { value } = this.state,
            { inError, isClearable, intl, placeholder, highlightOnEmpty, label, borderColor } = this.props,
            highlightClassName = inError ? 'input__in-error' : !value && highlightOnEmpty ? 'input--empty' : '';

        return (
            <div
                className={`input-with-autocomplete input__outer-wrapper ${highlightClassName} do_no_popunder`}
                style={{ borderColor }}
            >
                <Autosuggest
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.fetchSuggestions}
                    onSuggestionsClearRequested={this.clearSuggestions}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderInputComponent={this.getInputWrapper}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    focusInputOnSuggestionClick={false}
                    highlightFirstSuggestion={true}
                    inputProps={{
                        value,
                        autoComplete: Autocomplete.OFF,
                        placeholder: placeholder && intl.formatMessage(placeholder),
                        onBlur: this.onBlur,
                        onChange: this.onChange,
                        onFocus: this.onFocus,
                        type: 'text',
                        className: `input__input-el ${label ? 'input__input-el--with-label' : ''}`,
                        name: '__i' + this.randomInt, // Random name to bust browsers' attempts to autocomplete fields based on previous submissions
                        ref: this.props.inputRef
                    }}
                />
                {isClearable && !!value.length && (
                    <div className='input-with-autocomplete__clear' onClick={this.onClear}>
                        <AwdIcon name={AwdIconName.ExCircle} />
                    </div>
                )}
            </div>
        );
    }

    public componentDidMount() {
        this.isComponentMounted = true;
    }

    public componentWillUnmount() {
        this.isComponentMounted = false;
    }

    private clearSuggestions = () => {
        this.setState({ suggestions: [] });
    };

    private fetchSuggestions = (query: Autosuggest.SuggestionsFetchRequestedParams) => {
        if (query.value === this.state.value) {
            // this library calls onSuggestionsFetchRequested when the input is focused. if that happens & the value
            // is unchanged, there's no reason to fetch suggestions again
            return;
        }
        this.props.getSuggestions(query.value).then(suggestions => {
            this.isComponentMounted && this.setState({ suggestions });
        });
    };

    private getSuggestionValue = (suggestion: SuggestionDecorator<T>) => suggestion.text;

    private onChange = ({}, { newValue }: Autosuggest.ChangeEvent) => {
        this.setState({ value: newValue });
    };

    private onSuggestionSelected = (
        {},
        { suggestion, method }: Autosuggest.SuggestionSelectedEventData<SuggestionDecorator<T>>
    ) => {
        const suggestionChanged = suggestion && !isEqual(suggestion, this.state.suggestionSelected);
        this.setState({ suggestionSelected: suggestion });
        if (method === 'enter') {
            // Very annoying react-autosuggest issue: if the suggestion is selected by pressing enter,
            // onBlur does not get called (even though the input loses focus) so we have to separately
            // indicate that a suggestion was selected here
            suggestionChanged && this.props.onSuggestionSelected(suggestion);
        }
        return suggestion;
    };

    /**
     * Returns the a node wrapping the actual input element that we then pass to the
     * Autosuggest component. This is kinda hacky but it allows us to have the autosuggest
     * modal show up below the entire field (with icon etc), instead of just the input element.
     * Doing so otherwise is very hard and looks bad.
     *
     * More details here: https://github.com/moroshko/react-autosuggest#renderinputcomponent-optional
     *
     * @param inputProps
     */
    private getInputWrapper = (inputProps: {}) => {
        const { iconName, iconColor, label, showSpinnerInsteadOfIcon } = this.props;
        return (
            <div
                className={`input__inner-wrapper do_no_popunder ${
                    iconName || showSpinnerInsteadOfIcon ? '' : 'input__inner-wrapper__no-icon'
                }`}
            >
                {showSpinnerInsteadOfIcon && (
                    <span className='input__spinner'>
                        <Spinner color={SpinnerColor.Black} />
                    </span>
                )}
                {iconName && !showSpinnerInsteadOfIcon && (
                    <span
                        className='input__icon'
                        style={{
                            ...getIconAdjustments({ iconName }),
                            ...{ color: iconColor ? iconColor : 'inherit' }
                        }}
                    >
                        <AwdIcon name={iconName} />
                    </span>
                )}
                <div className='input__with-label'>
                    <div className='input__label'>{label && <FormattedMessage {...label} />}</div>
                    <input {...inputProps} />
                </div>
            </div>
        );
    };

    private renderSuggestion = (suggestion: SuggestionDecorator<T>) => {
        return <div className='input-with-autocomplete__suggestion do_no_popunder'>{suggestion.text}</div>;
    };

    private shouldRenderSuggestions = (query: string) => {
        return query.length >= this.props.minLengthOfQuery!;
    };

    /**
     * When the input is blurred we check to ensure that the data we're storing is not just
     * free text inserted by the user, but an actual suggestion selected from valid options
     * in the autosuggest. If it's not, then we reset the input value to be blank.
     *
     * This function also calls any on blur handlers attached to the component with the
     * selected suggestion
     *
     * @param highlightedSuggestion
     */
    private onBlur = ({}, { highlightedSuggestion }: Autosuggest.BlurEvent<SuggestionDecorator<T>>) => {
        const suggestion = highlightedSuggestion || this.state.suggestionSelected,
            suggestionChanged = highlightedSuggestion && !isEqual(highlightedSuggestion, this.state.suggestionSelected);
        this.setState({ value: (suggestion && suggestion.text) || '' });
        // we call onSuggestionSelected here because it's where we set the display value for the input, and we should
        // make sure anytime this changes we also update the selected suggestion
        suggestion && suggestionChanged && this.props.onSuggestionSelected(suggestion);
        return this.props.onBlur && this.props.onBlur(suggestion);
    };

    private onClear = () => {
        this.setState({ value: '' });
        return this.props.onClear && this.props.onClear();
    };

    /**
     * When the input is focused, we clear any text already present so that the user can insert
     * a new value. Since we store the suggestion selected separately, if the user then focuses
     * out of the input before choosing another suggestion, the previous suggestion text will be
     * returned to the input.
     */
    private onFocus = () => {
        this.setState({ value: '' });
    };
}

export default injectIntl(InputWithAutocomplete);
