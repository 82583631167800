import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import msgs from './messages';
import Airport from 'models/Airport';
import { getLocationNamePrimary } from 'utils/location';
import Checkbox, { CheckboxTheme } from 'components/Checkbox';

interface TrackDestinationCardProps {
    destination: Airport;
    imageUrl: string;
    onClick: (destination: Airport) => Promise<void>;
    onImgLoad?: (destination: Airport) => void;
    onImgError?: (destination: Airport) => void;
}

export default function TrackDestinationCard({
    destination,
    imageUrl,
    onClick,
    onImgLoad,
    onImgError
}: TrackDestinationCardProps) {
    const [imgLoaded, setImgLoaded] = useState(false),
        [checked, setChecked] = useState(false),
        [subscribed, setSubscribed] = useState(false),
        [inError, setInError] = useState(false),
        submitted = subscribed || inError,
        cityName = getLocationNamePrimary(destination.city),
        clickHandler = async () => {
            if (submitted) {
                return;
            }

            setChecked(true);
            try {
                await onClick(destination);
                setSubscribed(true);
            } catch (e) {
                setInError(true);
                throw e;
            }
        };

    return (
        <div
            className='track-destination-card'
            onClick={clickHandler}
            style={{ display: imgLoaded ? undefined : 'none' }}
        >
            <div className={'track-destination-card__img-wrapper'}>
                <img
                    className={'track-destination-card__img'}
                    src={imageUrl}
                    alt={cityName}
                    onLoad={() => {
                        setImgLoaded(true);
                        onImgLoad && onImgLoad(destination);
                    }}
                    onError={() => onImgError && onImgError(destination)}
                />
            </div>
            <div className={'track-destination-card__destination'}>{cityName}</div>
            <div className={'track-destination-card__msg'}>
                {!submitted && (
                    <div className={'track-destination-card__toggle'}>
                        <Checkbox checked={checked} theme={CheckboxTheme.FlatCircle}>
                            <FormattedMessage {...msgs.cta} />
                        </Checkbox>
                    </div>
                )}
                {subscribed && (
                    <div className={'track-destination-card__success'}>
                        <FormattedMessage {...msgs.success} />
                    </div>
                )}
                {inError && (
                    <div className={'track-destination-card__error'}>
                        <FormattedMessage {...msgs.error} />
                    </div>
                )}
            </div>
        </div>
    );
}
