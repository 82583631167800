import React from 'react';

import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';

export enum ArrowDirection {
    UP = 'up',
    DOWN = 'down',
    LEFT = 'left',
    RIGHT = 'right'
}

interface AwdArrowProps {
    direction: ArrowDirection;
    circled?: boolean;
    onClick?: () => void;
}

function getIconName(direction: ArrowDirection) {
    switch (direction) {
        case ArrowDirection.UP:
            return AwdIconName.ArrowUp;
        case ArrowDirection.DOWN:
            return AwdIconName.ArrowDown;
        case ArrowDirection.LEFT:
            return AwdIconName.ArrowLeft;
        case ArrowDirection.RIGHT:
            return AwdIconName.ArrowRight;
    }
}

export default function AwdArrow({ direction, circled = true, onClick }: AwdArrowProps) {
    return (
        <div
            className={`awd-arrow-root ${circled ? 'awd-arrow-root--circled' : ''}`}
            onClick={() => onClick && onClick()}
        >
            <span className={`awd-arrow-${direction}`}>
                <AwdIcon name={getIconName(direction)} />
            </span>
        </div>
    );
}
