import SearchData, { SearchType } from 'models/SearchData';
import { Subscription } from 'models/Subscriptions';
import { isAirportSuggestion, isCitySuggestion, Suggestion } from 'models/Suggestion';
import SubscriptionFrequency from 'models/Subscriptions/enums/SubscriptionFrequency';
import NewsletterProductId from 'models/Subscriptions/enums/NewsletterProductId';
import { AwdSiteId, Site, SiteId } from 'models/Sites';
import { SITE_TO_NL_PRODUCT_IDS } from 'constants/subscriptions';
import { getSiteFromSiteId } from 'utils/sites';

/**
 * A list of subscription types that every new AWD user will be subscribed to, and that don't
 * require additional data (e.g. locations)
 */
const BASE_NL_PRODUCT_IDS_AWD = [
    NewsletterProductId.HWD_UPDATES,
    NewsletterProductId.AWD_UPDATES,
    NewsletterProductId.AWD_INSIDER,
    NewsletterProductId.AWD_SPECIAL_OFFER,
    NewsletterProductId.TRIPWATCH
];

/**
 * A list of subscription types that every new AWD UK user will be subscribed to, and that don't
 * require additional data (e.g. locations)
 */
const BASE_NL_PRODUCT_IDS_AWDUK = [NewsletterProductId.AWDUK_UPDATES, NewsletterProductId.AWDUK_TRIPWATCH];

export function getBaseAwdSubscriptions(siteId: AwdSiteId) {
    const baseNlIds = siteId === SiteId.AWD_UK ? BASE_NL_PRODUCT_IDS_AWDUK : BASE_NL_PRODUCT_IDS_AWD,
        site = getSiteFromSiteId(siteId);

    return baseNlIds.map(productId => getSubscriptionWithFrequency({ productId, site }));
}

/**
 * Gets a collection of subscription types appropriate for the provided search data
 *
 * @param searchData
 * @param siteId
 */
export function getAwdSubscriptionForSearchData<T extends Suggestion>(
    searchData: SearchData<T>,
    siteId: AwdSiteId
): Subscription[] {
    const { location1, location2 } = searchData;

    // Subscriptions for hotel searches:
    if (searchData.searchType === SearchType.HOTEL && location2 && isCitySuggestion(location2.suggestion)) {
        const site = getSiteFromSiteId(siteId),
            baseSubscriptions = getBaseAwdSubscriptions(siteId),
            cityNlId = siteId === SiteId.AWD_UK ? NewsletterProductId.AWDUK_HWD_CITY : NewsletterProductId.HWD_CITY,
            citySubscription = getSubscriptionWithFrequency({ productId: cityNlId, site });

        return [...baseSubscriptions, { ...citySubscription, destinationId: location2.suggestion.id }];
    }

    // Subscriptions for flight searches
    let departureAirportCode, arrivalAirportCode, arrivalCityId;

    if (location1 && isAirportSuggestion(location1.suggestion)) {
        departureAirportCode = location1.suggestion.airportCode;
    }

    if (location2 && isAirportSuggestion(location2.suggestion)) {
        arrivalAirportCode = location2.suggestion.airportCode;
        arrivalCityId = location2.suggestion.parentId;
    }

    return getAwdSubscriptionForAirports({ departureAirportCode, arrivalAirportCode, arrivalCityId, siteId });
}

/**
 * Gets a collection of subscription types appropriate for the provided airport codes
 *
 * @param departureAirportCode
 * @param arrivalAirportCode
 * @param arrivalCityId
 * @param siteId
 */
export function getAwdSubscriptionForAirports({
    departureAirportCode,
    arrivalAirportCode,
    arrivalCityId,
    siteId
}: {
    departureAirportCode?: string;
    arrivalAirportCode?: string;
    arrivalCityId?: number;
    siteId: AwdSiteId;
}): Subscription[] {
    const site = getSiteFromSiteId(siteId),
        subscriptions: Subscription[] = [...getBaseAwdSubscriptions(siteId)],
        hasDepartureAirport = departureAirportCode && departureAirportCode.length,
        hasArrivalAirport = arrivalAirportCode && arrivalAirportCode.length;

    if (hasDepartureAirport) {
        const productId =
                siteId === SiteId.AWD_UK ? NewsletterProductId.AWDUK_DEPARTURE : NewsletterProductId.AWD_DEPARTURE,
            subscription = getSubscriptionWithFrequency({ productId, site });

        subscriptions.push({ ...subscription, originCode: departureAirportCode });
    }

    if (hasDepartureAirport && hasArrivalAirport) {
        const productId =
                siteId === SiteId.AWD_UK
                    ? NewsletterProductId.AWDUK_CITY_TO_CITY
                    : NewsletterProductId.AWD_CITY_TO_CITY,
            subscription = getSubscriptionWithFrequency({ productId, site });

        subscriptions.push({ ...subscription, originCode: departureAirportCode, destinationCode: arrivalAirportCode });
    }

    if (arrivalCityId) {
        const productId = siteId === SiteId.AWD_UK ? NewsletterProductId.AWDUK_HWD_CITY : NewsletterProductId.HWD_CITY,
            subscription = getSubscriptionWithFrequency({ productId, site });

        subscriptions.push({ ...subscription, destinationId: arrivalCityId });
    }

    return subscriptions;
}

export function getSubscriptionWithFrequency({ productId, site }: { productId: NewsletterProductId; site: Site }) {
    if (!SITE_TO_NL_PRODUCT_IDS[site].includes(productId)) {
        throw new Error(`Invalid product id for provided site: { site: "${site}", productId: "${productId}" }`);
    }

    return { productId, frequency: getSubscriptionFrequency(productId) };
}

/**
 * For a given subscription return the corresponding desired frequency we want to assign to each. So long as we
 * haven't switched all subscription code to React, this should be kept in sync with the
 * corresponding map in com.smartertravel.awd.model.subscription.SubscriptionFrequency.SUBSCRIPTION_FREQUENCY_MAP
 *
 * Note that, when newsletters require frequencies, if we do not provide one the
 * campaign service will not assign a default.
 *
 * To see if a given newsletter id needs/supports frequencies see for example
 * http://campaignbuilder.st.cognius.net/api/newsletterProducts/2849536 (for
 * the AWD_DEPARTURE newsletter
 */
function getSubscriptionFrequency(arg: NewsletterProductId | Subscription): SubscriptionFrequency | undefined {
    const productId = (arg as Subscription).productId || (arg as NewsletterProductId);
    switch (productId) {
        case NewsletterProductId.JS_DEALS:
        case NewsletterProductId.JS_GENERAL:
        case NewsletterProductId.JS_INSPIRATION:
        case NewsletterProductId.ST_DEAL_ALERT:
            return SubscriptionFrequency.Daily;
        case NewsletterProductId.AWD_ARRIVAL:
            return SubscriptionFrequency.Weekly;
        case NewsletterProductId.AWD_DEPARTURE:
        case NewsletterProductId.AWDUK_DEPARTURE:
        case NewsletterProductId.AWD_INSIDER:
        case NewsletterProductId.FVC_INSIDER:
            return SubscriptionFrequency.Frequent;
    }
}
