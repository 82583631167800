import React from 'react';

import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import Spinner, { SpinnerColor } from 'components/Spinner';
import { getIconAdjustments } from 'components/Input/common/utils';
import Autocomplete from 'constants/autocomplete';

interface InputFreeTextProps {
    autoComplete?: Autocomplete;
    backgroundColor?: string; // Optional override for the input field's background color
    highlightOnEmpty?: boolean;
    borderColor?: string; // Optional override for the input field's border color
    showSpinnerInsteadOfIcon?: boolean;
    iconColor?: string;
    iconName?: AwdIconName;
    label?: FormattedMessage.MessageDescriptor; // Label text for the input
    placeholder?: FormattedMessage.MessageDescriptor; // Placeholder text for the input
    value?: string | null;
    inError?: boolean;
    intl: InjectedIntl; // Prop automatically injected by injectIntl: https://github.com/yahoo/react-intl/wiki/API#injectintl
    onBlur?: (value: string) => void; // Handler called when the input's value is blurred
    onChange?: (value: string) => void; // Handler called when the input's value changes
    inputRef?: React.RefObject<HTMLInputElement>;
}

interface InputFreeTextState {
    value: string; // Current input value,
}

/**
 * Implementation of Input for free text like emails etc
 */
class InputFreeText extends React.Component<InputFreeTextProps, InputFreeTextState> {
    private readonly randomInt: number;

    constructor(props: InputFreeTextProps) {
        super(props);
        this.state = { value: props.value || '' };
        this.randomInt = Math.floor(Math.random() * 1_000_000_000);
    }

    public render() {
        const { value } = this.state,
            {
                backgroundColor,
                borderColor,
                intl,
                placeholder,
                inError,
                label,
                iconColor,
                highlightOnEmpty,
                showSpinnerInsteadOfIcon,
                iconName
            } = this.props,
            outerWrapperStyle = { backgroundColor, borderColor },
            highlightClassName = inError ? 'input__in-error' : !value && highlightOnEmpty ? 'input--empty' : '';

        return (
            <div
                className={`input-free-text input__outer-wrapper ${highlightClassName} do_no_popunder`}
                style={outerWrapperStyle}
            >
                <div
                    className={`input__inner-wrapper ${
                        iconName || showSpinnerInsteadOfIcon ? '' : 'input__inner-wrapper__no-icon'
                    }`}
                >
                    {showSpinnerInsteadOfIcon && (
                        <span className='input__spinner'>
                            <Spinner color={SpinnerColor.Black} />
                        </span>
                    )}
                    {iconName && !showSpinnerInsteadOfIcon && (
                        <div
                            className='input__icon'
                            style={{
                                ...getIconAdjustments({ iconName }),
                                ...{ color: iconColor ? iconColor : 'inherit' }
                            }}
                        >
                            <AwdIcon name={iconName} />
                        </div>
                    )}
                    <div className='input__with-label'>
                        <div className='input__label'>{label && <FormattedMessage {...label} />}</div>
                        <input
                            value={value}
                            placeholder={placeholder && intl.formatMessage(placeholder)}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            className={`input__input-el ${label ? 'input__input-el--with-label' : ''}`}
                            type='text'
                            ref={this.props.inputRef}
                            autoComplete={this.getInputName()}
                            name={this.getInputName()}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private onBlur = (e: React.FormEvent<HTMLInputElement>) => {
        this.props.onBlur && this.props.onBlur(e.currentTarget.value);
    };

    private onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        this.setState({ value });
        this.props.onChange && this.props.onChange(value);
    };

    /**
     * Chrome (and some other browsers) are getting a bit too smart about offering autocomplete suggestions
     * based on what the user has previously input for fields with the same "name" and/or "autocomplete" fields. The
     * problem is that sometimes those suggestions aren't appropriate and may be confusing for users. So we
     * use a random number to bust this feature when we really want the autocomplete to be off.
     */
    private getInputName = () => {
        const { autoComplete } = this.props;

        if (autoComplete && autoComplete !== Autocomplete.OFF) {
            return autoComplete;
        }

        return '__i' + this.randomInt;
    };
}

export default injectIntl(InputFreeText);
