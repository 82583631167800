export enum DateFormat {
    dayMonth = 'D/M', // "25/10"
    monthDay = 'M/D', // "10/25"
    monthDayText = 'MMM D', // "Oct 3"
    weekdayDayMonth = 'ddd D/M', // "Fri 25/10"
    weekdayMonthDay = 'ddd M/D', // "Fri 10/25"
    monthFull = 'MMMM', // "December"
    monthYearFull = 'MMMM YYYY', // "April 2019"
    yearDashMonth = 'YYYY-MM' // "2019-12"
}
