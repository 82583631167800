import { renderRecentSearchModule } from 'apps/common/recentSearchModule';
import renderUserPreferencesMenus from 'apps/common/userPreferencesMenus';
import { AnyAction, Store } from 'redux';
import { StoreWithUserState } from 'ducks/user';
import { StoreWithConfigState } from 'ducks/config';
import { renderFooter } from 'apps/common/footer';
import { renderTopLocationsCarousel } from 'apps/common/topLocationsCarousel';

export function renderBaseComponents({
    store,
    reloadPageOnCurrencyChange
}: {
    store: Store<StoreWithUserState & StoreWithConfigState, AnyAction>;
    reloadPageOnCurrencyChange?: boolean;
}) {
    // START: AWD-3728
    renderRecentSearchModule({ store });
    // END: AWD-3728

    renderUserPreferencesMenus({ store, reloadPageOnCurrencyChange });

    renderFooter({ store });

    // START: AWD-3860
    renderTopLocationsCarousel({ store });
    // END: AWD-3860
}
