import { defineMessages } from 'react-intl';

export default defineMessages({
    headerCta: {
        id: 'TrackDestinationsCarousel_headerCta',
        defaultMessage: 'Stay inspired!'
    },
    headerMsg: {
        id: 'TrackDestinationsCarousel_headerMsg',
        defaultMessage: "Watch prices now for trips you'll want to take later"
    },
    disclaimer: {
        id: 'TrackDestinationsCarousel_disclaimer',
        defaultMessage:
            "By choosing to track prices, you agree to our <a href='https://www.smartertravel.com/privacy-policy/' target='_blank' rel='noopener'>Privacy Policy</a> and <a href='/terms-of-use/' target='_blank' rel='noopener'>Terms of Use</a>"
    }
});
