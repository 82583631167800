import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { AnyAction, Store } from 'redux';

import { getHomeAirport, getSubscriber, StoreWithUserState } from 'ducks/user';
import { getAwd3860Recipe, StoreWithConfigState } from 'ducks/config';
import AwdProvider from 'components/AwdProvider';
import ConnectedTrackDestinationsCarousel from 'components/TrackDestinationsCarousel/connected';
import { AbTestRecipe } from 'constants/abTests';
import { getCookie, setCookie } from 'utils/cookies';
import { CookieNames } from 'constants/cookies';
import { Subscription } from 'models/Subscriptions';
import Airport from 'models/Airport';
import { mediaGreaterThanSm } from 'constants/mediaQueries';
import Media from 'react-media';
import { getTopLocations } from 'api/topLocations';

function getSubscribedDestinations({ subscriptions }: { subscriptions: Subscription[] }) {
    const subscribedAirportCodes: string[] = [],
        subscribedCityIds: number[] = [];

    subscriptions.forEach(s => {
        const { destinationCode, destinationId } = s;

        destinationCode && subscribedAirportCodes.push(destinationCode);
        destinationId && subscribedCityIds.push(destinationId);
    });

    return { subscribedAirportCodes, subscribedCityIds };
}

async function fetchUnsubscribedTopLocations({
    origin,
    subscriptions
}: {
    origin: Airport;
    subscriptions: Subscription[];
}) {
    const { topAirports, images } = await getTopLocations({ origin, limit: 25, includeImages: true }),
        { subscribedAirportCodes, subscribedCityIds } = getSubscribedDestinations({ subscriptions });

    return {
        imageUrls: images,
        topLocations: topAirports.filter(a => {
            return !subscribedAirportCodes.includes(a.airportCode) && !subscribedCityIds.includes(a.city.id);
        })
    };
}

export async function renderTopLocationsCarousel({
    store
}: {
    store: Store<StoreWithUserState & StoreWithConfigState, AnyAction>;
}) {
    const state = store.getState(),
        recipe = getAwd3860Recipe(state),
        subscriber = getSubscriber(state),
        homeAirport = getHomeAirport(state),
        alreadyShown = getCookie(CookieNames.TOP_LOCATIONS_CAROUSEL_SHOWN) === 'true';

    if (alreadyShown || !subscriber || !homeAirport || recipe !== AbTestRecipe.AWD_3860_TOP_LOCATIONS) {
        return null;
    }

    const { subscriptions } = subscriber,
        { topLocations, imageUrls } = await fetchUnsubscribedTopLocations({ origin: homeAirport, subscriptions });

    if (!imageUrls) {
        return null;
    }

    ReactDOM.render(
        <Suspense fallback={null}>
            <AwdProvider store={store}>
                <Media query={mediaGreaterThanSm}>
                    {matches =>
                        matches && (
                            <ConnectedTrackDestinationsCarousel
                                destinations={topLocations}
                                imageUrls={imageUrls}
                                onExpanded={() =>
                                    setCookie({
                                        name: CookieNames.TOP_LOCATIONS_CAROUSEL_SHOWN,
                                        value: 'true',
                                        expireInDays: 7
                                    })
                                }
                            />
                        )
                    }
                </Media>
            </AwdProvider>
        </Suspense>,
        document.getElementById('react-root__top-locations-carousel') as HTMLElement
    );
}
