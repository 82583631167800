import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring/web.cjs';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import './styles.scss';
import msgs from './messages';
import Airport from 'models/Airport';
import TrackDestinationCard from 'components/TrackDestinationCard';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import AwdArrow, { ArrowDirection } from 'components/AwdArrow';
import { Waypoint } from 'react-waypoint';

interface TrackDestinationsCarouselProps {
    destinations: Airport[];
    imageUrls: { [key: string]: string }; // Mapping of airport codes to image URLs
    onDestinationClick: (destination: Airport) => Promise<void>;
    onExpanded?: () => void;
}

export default function TrackDestinationsCarousel({
    destinations,
    imageUrls,
    onDestinationClick,
    onExpanded
}: TrackDestinationsCarouselProps) {
    const [imagesLoadedCount, setImagesLoadedCount] = useState(0),
        [imagesFailedCount, setImagesFailedCount] = useState(0),
        [expanded, setExpanded] = useState(false),
        style = useSpring({ height: expanded ? 325 : 0 }),
        cardsWrapperRef = useRef() as MutableRefObject<HTMLDivElement | null>,
        firstCardRef = useRef() as MutableRefObject<HTMLDivElement | null>,
        lastCardRef = useRef() as MutableRefObject<HTMLDivElement | null>,
        [isFirstCardVisible, setFirstCardVisible] = useState(false),
        [isLastCardVisible, setLastCardVisible] = useState(false),
        scrollCards = (px: number) => {
            const cardsEl = cardsWrapperRef.current;
            cardsEl && cardsEl.scroll({ left: cardsEl.scrollLeft + px, behavior: 'smooth' });
        },
        scrollLeft = () => scrollCards(-500),
        scrollRight = () => scrollCards(500);

    // We wait until all images have either loaded or failed to load so that we don't
    // show destination cards with half-loaded images. Also, if all images fail to load for
    // whatever reason, we don't wanna show the carousel
    useEffect(() => {
        if (imagesLoadedCount > 0 && imagesLoadedCount + imagesFailedCount === destinations.length) {
            setExpanded(true);
        }
    }, [imagesLoadedCount, imagesFailedCount, destinations]);

    useEffect(() => {
        expanded && onExpanded && onExpanded();
    }, [expanded]);

    return (
        <animated.div className='track-destinations-carousel' style={style}>
            <div className={'track-destinations-carousel__close'} onClick={() => setExpanded(false)}>
                <AwdIcon name={AwdIconName.ArrowUp} />
            </div>
            <div className={'track-destinations-carousel__header'}>
                <span className={'track-destinations-carousel__header__cta'}>
                    <FormattedMessage {...msgs.headerCta} />
                </span>
                <span className={'track-destinations-carousel__header__msg'}>
                    <FormattedMessage {...msgs.headerMsg} />
                </span>
            </div>
            <div className={'track-destinations-carousel__cards-wrapper'}>
                {!isFirstCardVisible && (
                    <div className={'track-destinations-carousel__more-cards-left'} onClick={scrollLeft}>
                        <AwdArrow direction={ArrowDirection.LEFT} onClick={scrollLeft} />
                    </div>
                )}
                <div className={'track-destinations-carousel__cards'} ref={cardsWrapperRef}>
                    <Waypoint
                        onEnter={() => setFirstCardVisible(true)}
                        onLeave={() => setFirstCardVisible(false)}
                        horizontal={true}
                        scrollableAncestor={cardsWrapperRef.current}
                    />
                    {destinations.map((destination, i) => {
                        const { airportCode } = destination,
                            img = imageUrls[airportCode],
                            isFirst = i === 0,
                            isLast = i === destinations.length - 1;
                        return (
                            img && (
                                <div
                                    className={'track-destinations-carousel__card'}
                                    key={`destination-card-${airportCode}`}
                                    ref={isFirst ? firstCardRef : isLast ? lastCardRef : undefined}
                                >
                                    <TrackDestinationCard
                                        destination={destination}
                                        imageUrl={img}
                                        onClick={() => onDestinationClick(destination)}
                                        onImgLoad={() => setImagesLoadedCount(imagesLoadedCount + 1)}
                                        onImgError={() => setImagesFailedCount(imagesFailedCount + 1)}
                                    />
                                </div>
                            )
                        );
                    })}
                    <Waypoint
                        onEnter={() => setLastCardVisible(true)}
                        onLeave={() => setLastCardVisible(false)}
                        horizontal={true}
                        scrollableAncestor={cardsWrapperRef.current}
                    />
                </div>
                {!isLastCardVisible && (
                    <div className={'track-destinations-carousel__more-cards-right'}>
                        <AwdArrow direction={ArrowDirection.RIGHT} onClick={scrollRight} />
                    </div>
                )}
            </div>
            <div className={'track-destinations-carousel__disclaimer'}>
                <FormattedHTMLMessage {...msgs.disclaimer} />
            </div>
        </animated.div>
    );
}
