import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import queryString from 'query-string';

import Airport from 'models/Airport';
import { BlogPost } from 'models/Blog';
import { FareAction, FareNumStops, FareSort, FareType } from 'models/Fares';
import SearchData, { SearchDataLocations, SearchDataWithRequiredLocation2, SearchType } from 'models/SearchData';
import { AirportSuggestion, CitySuggestion, Suggestion } from 'models/Suggestion';
import { getLocationNameDisplayMed } from 'utils/location';
import { removeNonAsciiCharacters } from 'utils/string';
import { IncomingFilterableFareDetail } from 'models/incomingData/Fares';
import { getFareSortAsString } from 'utils/fares/sorting';

/**
 * This file contains both generic url utilities and functions specifically for building urls for AWD pages.
 */

const BASE_PATH = '/',
    BLOG_PATH = `${BASE_PATH}blog/`,
    AIR_SEARCH_PATH = `${BASE_PATH}search/air/`,
    FARES_BASE_PATH = `${BASE_PATH}cheap-flights/`,
    DATE_FORMAT = 'YYYY-MM-DD',
    HOTEL_DATE_FORMAT = 'YYYYMMDD';

export interface FareListingUrlFilterOptions {
    filterRoute?: AirportSuggestion;
    filterIncludeAirlineCodes?: string[];
    filterExcludeAirlineCodes?: string[];
    filterNumStops?: FareNumStops;
    filterFareType?: FareType;
    filterWeekendOnly?: boolean;
    filterTravelMonths?: string[];
    sort?: FareSort;
}

/**
 * Generates a query param string from a flat object of primitives. Will filter out any parameters with
 * a value of null, undefined, or empty string. If any parameters are left, the resulting string will
 * begin with "?"
 *
 * @param params
 */
export function buildQueryParamString(params: { [key: string]: string | number | boolean | undefined | null }): string {
    const paramsFiltered = omitBy(params, v => isNil(v) || v === ''),
        paramsString = reduce(
            paramsFiltered,
            (str, v, k) => str + `${!str ? '' : '&'}${k}=${encodeURIComponent(`${v}`)}`,
            ''
        );

    return isEmpty(paramsString) ? '' : `?${paramsString}`;
}

export function parseQueryParamString(queryParamString: string) {
    return queryString.parse(queryParamString) as { [key: string]: string | number | boolean | undefined | null };
}

/**
 * Build the URL for the provided post, using its slug and url-title
 *
 * @param post
 */
export function buildBlogPostUrl({ post }: { post: BlogPost }): string {
    // We grab the id form the slug instead of using the id property because the id
    // property is only the WP id of the post. But for posts not initially created in wordpress
    // (i.e. all our legacy posts) that id does not match the id in our URLs. The id included
    // after "id-" in the slug always macthes the id in our URLS, so we use that:
    const idFromSlug = post.slug.replace(/^id-/, '');
    return `${BLOG_PATH}${idFromSlug}/${post.urlTitle}`;
}

/**
 * This function builds a functional search URL from a search data object. The resulting URL
 * redirects the user to tabbed-browsing
 */
export function buildTabbedBrowsingUrl<T extends Suggestion>({ searchData }: { searchData: SearchData<T> }): string {
    const mode = searchData.searchType;

    if (mode !== SearchType.AIR) {
        throw new Error(`Mode "${mode}" unsupported in tabbed browsing.`);
    }

    const origin = searchData.location1,
        destination = searchData.location2,
        departureDate = searchData.date1,
        returnDate = searchData.date2,
        paramsStr = buildQueryParamString({
            mode,
            flightType: searchData.flightSearchType,
            originId: origin && origin.suggestion.id,
            destinationId: destination && destination.suggestion.id,
            departureDate: departureDate && departureDate.format(DATE_FORMAT),
            returnDate: returnDate && returnDate.format(DATE_FORMAT),
            numTravelers: searchData.travelers
        });

    return `${AIR_SEARCH_PATH}${paramsStr}`;
}

export function buildFareListingFilterQueryParams(
    filterOptions?: FareListingUrlFilterOptions
): { [key: string]: string | number | null | undefined } {
    if (!filterOptions) {
        return {};
    }

    filterOptions.filterIncludeAirlineCodes = filterOptions.filterIncludeAirlineCodes || [];
    filterOptions.filterExcludeAirlineCodes = filterOptions.filterExcludeAirlineCodes || [];
    filterOptions.filterTravelMonths = filterOptions.filterTravelMonths || [];

    const filterDetailsValue = [
        filterOptions.filterNumStops,
        filterOptions.filterWeekendOnly ? IncomingFilterableFareDetail.WEEKEND : null
    ]
        .filter(v => !!v)
        .join(',');

    const params: { [key: string]: string | number | null | undefined } = {
        filter_location: get(filterOptions, 'filterRoute.airportCode'),
        filter_type: filterOptions.filterFareType,
        filter_details: filterDetailsValue,
        filter_months: filterOptions.filterTravelMonths.join(','),
        sortedBy: filterOptions.sort ? getFareSortAsString(filterOptions.sort) : null
    };

    if (filterOptions.filterIncludeAirlineCodes.length < filterOptions.filterExcludeAirlineCodes.length) {
        params.include_airlines = filterOptions.filterIncludeAirlineCodes.join(',');
    } else {
        params.exclude_airlines = filterOptions.filterExcludeAirlineCodes.join(',');
    }

    return omitBy(params, v => isNil(v) || v === '');
}

/**
 * Builds a url for a fare listing page for the given airport or airport suggestion.
 * @param fareAction
 * @param airport
 * @param filterOptions
 */
export function buildFareListingUrl({
    fareAction,
    airport,
    filterOptions
}: {
    fareAction: FareAction;
    airport: Airport | AirportSuggestion;
    filterOptions?: FareListingUrlFilterOptions;
}): string {
    let locationName: string;

    if ((airport as Airport).city) {
        locationName = getLocationNameDisplayMed((airport as Airport).city);
    } else {
        locationName = (airport as AirportSuggestion).parentDisplayName;
    }

    locationName = locationName.replace(/[,()]/g, '').replace(/[\s\/]/g, '-');
    const normalizedLocationName = removeNonAsciiCharacters(locationName),
        airportCode = airport.airportCode,
        queryString = buildQueryParamString(buildFareListingFilterQueryParams(filterOptions));

    return `${FARES_BASE_PATH}${fareAction.toLowerCase()}-${normalizedLocationName}/${airportCode}/${queryString}`;
}

/**
 * Builds a url for a fare details page with the given search data.
 * @param location1
 * @param location2
 */
export function buildFareDetailsUrl({ location1, location2 }: SearchDataLocations<AirportSuggestion>): string {
    const locationSlug = (
        location1.suggestion.parentDisplayName +
        '-' +
        location1.suggestion.airportCode +
        '-to-' +
        location2.suggestion.parentDisplayName +
        '-' +
        location2.suggestion.airportCode
    )
        .replace(/[,()]/g, '')
        .replace(/[\s\/]/g, '-');
    const normalizedLocationSlug = removeNonAsciiCharacters(locationSlug);

    return `${FARES_BASE_PATH}${normalizedLocationSlug}/`;
}

export function buildHotelListingUrl({
    location2,
    date1,
    date2
}: SearchDataWithRequiredLocation2<CitySuggestion>): string {
    const queryString = buildQueryParamString({
        checkin: date1 && date1.format(HOTEL_DATE_FORMAT),
        checkout: date2 && date2.format(HOTEL_DATE_FORMAT)
    });

    return `${BASE_PATH}${location2.suggestion.id}TA/hotels/${queryString}`;
}

/**
 * Simple function to remove any subdomain from the hostname
 */
export function getBaseDomain() {
    return window.location.hostname
        .split('.')
        .slice(1)
        .join('.');
}
