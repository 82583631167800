import { defineMessages } from 'react-intl';

export default defineMessages({
    cta: {
        id: 'RecentSearchCollapsible_cta',
        defaultMessage: 'Look! We found a new price for your trip!'
    },
    to: {
        id: 'RecentSearchCollapsible_to',
        defaultMessage: 'to'
    },
    searchButton: {
        id: 'RecentSearchCollapsible_seeDeal',
        defaultMessage: "See Today's Deal"
    }
});
