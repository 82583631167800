import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import './styles.scss';
import msgs from './messages';
import { AwdSiteId } from 'models/Sites';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import {
    PATH_ABOUT_US,
    PATH_CONTACT_AWD,
    PATH_COOKIE_POLICY,
    PATH_FARE_ALERTS_PREFERENCES,
    PATH_PRIVACY_STATEMENT,
    PATH_TERMS_OF_USE
} from 'constants/paths';
import { Subscriber } from 'models/Subscriptions';
import { ThunkDispatch } from 'redux-thunk';
import { addOrUpdateAwdSubscriber, StoreWithUserState } from 'ducks/user';
import { StoreWithConfigState } from 'ducks/config';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { InputFreeText } from 'components/Input';
import { getBaseAwdSubscriptions } from 'utils/subscriptions';

interface FooterProps {
    siteId: AwdSiteId;
    queueNewSubscriber(subscriber: Subscriber): void;
}

interface FooterState {
    emailAddress: string;
    isSignedUp: boolean;
}

export class Footer extends React.Component<FooterProps, FooterState> {
    constructor(props: FooterProps) {
        super(props);
        this.state = { emailAddress: '', isSignedUp: false };
    }

    public render = () => {
        let button;

        if (this.state.isSignedUp) {
            button = (
                <span className='react-footer__signedup-entry-email-submit'>
                    <Button text={msgs.signedup} />
                </span>
            );
        } else {
            button = (
                <span className='react-footer__signup-entry-email-submit'>
                    <Button text={msgs.signup} onClick={() => this.onSubscriptionClicked(this.props.siteId)} />
                </span>
            );
        }

        return (
            <div className='react-footer'>
                <div className='react-footer__wrapper'>
                    <div className='react-footer__top-row '>
                        <div className='react-footer__signup'>
                            <div className='react-footer__signup-label'>
                                <FormattedMessage {...msgs.signupLabel} />
                            </div>
                            <div className='react-footer__signup-entry'>
                                <InputFreeText
                                    placeholder={msgs.emailPlaceholder}
                                    value={this.state.emailAddress}
                                    onChange={this.onSubscriptionChanged}
                                />
                                {button}
                            </div>
                            <div className='react-footer__search-disclaimer-wrapper'>
                                <div className='react-footer__search_disclaimer'>
                                    <FormattedHTMLMessage {...msgs.subscribeDisclaimer} />
                                </div>
                            </div>
                        </div>
                        <div className='react-footer__links'>
                            <div className='react-footer__links-group'>
                                <ul className='react-footer__links-group-list'>
                                    <li>
                                        <a href={PATH_ABOUT_US} rel='publisher'>
                                            <FormattedMessage {...msgs.about} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={PATH_CONTACT_AWD} rel='nofollow'>
                                            <FormattedMessage {...msgs.contact} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='react-footer__links-group'>
                                <ul className='react-footer__links-group-list'>
                                    <li>
                                        <a href={PATH_TERMS_OF_USE}>
                                            <FormattedMessage {...msgs.terms} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={PATH_FARE_ALERTS_PREFERENCES}>
                                            <FormattedMessage {...msgs.preferences} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='react-footer__links-group'>
                                <ul className='react-footer__links-group-list'>
                                    <li>
                                        <a href={PATH_PRIVACY_STATEMENT}>
                                            <FormattedMessage {...msgs.privacy} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={PATH_COOKIE_POLICY}>
                                            <FormattedMessage {...msgs.cookie} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='react-footer__legal'>
                        <div className='react-footer__icons'>
                            <AwdIcon name={AwdIconName.Dog} />
                        </div>
                        <div className='react-footer__copyright'>
                            <div>
                                <FormattedMessage {...msgs.legal} values={{ date: new Date().getFullYear() }} />
                            </div>
                            <div>
                                <FormattedMessage {...msgs.rights} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private onSubscriptionChanged = (value: string) => {
        this.setState({ emailAddress: value });
    };

    private onSubscriptionClicked = (siteId: AwdSiteId) => {
        this.setState({ isSignedUp: true });
        this.props.queueNewSubscriber({
            emailAddress: this.state.emailAddress,
            subscriptions: getBaseAwdSubscriptions(siteId)
        });
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreWithUserState & StoreWithConfigState, void, AnyAction>) => ({
    queueNewSubscriber: (subscriber: Subscriber) => {
        dispatch(addOrUpdateAwdSubscriber({ subscriber }));
    }
});

export default connect(null, mapDispatchToProps)(Footer);
