import { AbTestDecision, AbTests } from 'models/AbTest';
import { Locale } from 'models/Locale';
import { AwdSite, AwdSiteId } from 'models/Sites';
import { AbTestName, AbTestRecipe } from 'constants/abTests';
import { getSiteFromSiteId } from 'utils/sites';
import { Faq } from 'models/Faq';

/**
 * Contains state that is commonly used across pages and doesn't need to change after page load
 */
export type ConfigState = Readonly<{
    abTests?: AbTests;
    faqs?: Faq[];
    locale?: Locale;
    siteId?: AwdSiteId;
    staticBaseUrl?: string;
}>;

export interface StoreWithConfigState {
    config: ConfigState;
}

export default function configReducer(state: ConfigState = {}): ConfigState {
    return state;
}

// Many of these selectors are set up expecting that, e.g. state.config.locale will always be defined. This is how we
// handle required fields: we want the page to error out if for some reason these required fields are missing.
export const getLocale = (state: StoreWithConfigState): Locale => state.config.locale!;
export const isAwdUS = (state: StoreWithConfigState): boolean => Locale.EnglishUS.equals(getLocale(state));
export const getSiteId = (state: StoreWithConfigState): AwdSiteId => state.config.siteId!;
export const getSite = (state: StoreWithConfigState): AwdSite => getSiteFromSiteId(getSiteId(state));
export const getAbTests = (state: StoreWithConfigState): AbTests => state.config.abTests!;
export const getAbTest = (state: StoreWithConfigState, testName: AbTestName): AbTestDecision | undefined =>
    getAbTests(state)[testName];
export const getStaticBaseUrl = (state: StoreWithConfigState): string => state.config.staticBaseUrl!;
export const getStaticFilepathFactory = (state: StoreWithConfigState) => (filename: string) => {
    return getStaticBaseUrl(state) + 'react/' + filename;
};
export const getFaqs = (state: StoreWithConfigState): Faq[] | undefined => state.config.faqs;

// START: AWD-3560
export const getAwd3560Recipe = (state: StoreWithConfigState): AbTestRecipe | undefined => {
    const awd3560 = getAbTest(state, AbTestName.AWD_3560);
    return awd3560 && awd3560.recipe;
};
// END: AWD-3560

// START: AWD-3430
export const getAwd3430Recipe = (state: StoreWithConfigState): AbTestRecipe | undefined => {
    const awd3430 = getAbTest(state, AbTestName.AWD_3430);
    return awd3430 && awd3430.recipe;
};
// END: AWD-3430

// START: AWD-3860
export const getAwd3860Recipe = (state: StoreWithConfigState): AbTestRecipe | undefined => {
    const awd3860 = getAbTest(state, AbTestName.AWD_3860);
    return awd3860 && awd3860.recipe;
};
// END: AWD-3860
