import { Subscriber, UpdateSubscriberResponse } from 'models/Subscriptions';
import SubscriptionError from 'models/Errors/SubscriptionError';
import { Site } from 'models/Sites';
declare global {
    interface Window {
        stm: {
            collectionSvcTrackingData: {
                pageStateId: string;
            };
        };
    }
}

const SUBSCRIBER_ENDPOINT = '/subscriber/';

const getSubscriberPath = (site: Site) => SUBSCRIBER_ENDPOINT + `${site}/`;

const createExtraQueryParams = () => {
    let pageStateIdQuery = '';

    if (window.stm.collectionSvcTrackingData.pageStateId) {
        pageStateIdQuery = '?pageStateId=' + window.stm.collectionSvcTrackingData.pageStateId;
    }

    return pageStateIdQuery;
};

export async function postSubscriber({
    subscriber,
    site
}: {
    subscriber: Subscriber;
    site: Site;
}): Promise<UpdateSubscriberResponse> {
    const res = await fetch(getSubscriberPath(site) + createExtraQueryParams(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(subscriber)
    });

    if (!res.ok) {
        throw new SubscriptionError(`Failed to add or update subscriber to site "${site}"`);
    }

    return (await res.json()).success;
}

export async function patchSubscriber({
    subscriber,
    site
}: {
    subscriber: Subscriber;
    site: Site;
}): Promise<UpdateSubscriberResponse> {
    if (!subscriber.trackingCode) {
        throw new SubscriptionError(`Can't patch a subscriber without tracking code`);
    }

    const res = await fetch(getSubscriberPath(site) + createExtraQueryParams(), {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(subscriber)
    });

    if (!res.ok) {
        throw new SubscriptionError(`Failed to update subscriber`);
    }

    return (await res.json()).success;
}
