import { connect } from 'react-redux';

import { addOrUpdateAwdSubscriber, getHomeAirport, getSubscriber, StoreWithUserState } from 'ducks/user';
import { ThunkDispatch } from 'redux-thunk';
import { AirSearchFormState } from 'ducks/airSearchForm';
import { AnyAction } from 'redux';
import { getAwdSubscriptionForAirports } from 'utils/subscriptions';
import { Subscriber } from 'models/Subscriptions';
import { AwdSiteId } from 'models/Sites';
import TrackDestinationsCarousel from './index';
import Airport from 'models/Airport';
import { getSiteId, StoreWithConfigState } from 'ducks/config';

interface OwnProps {
    destinations: Airport[];
    imageUrls: { [key: string]: string };
    onExpanded?: () => void;
}

const mapStateToProps = (state: StoreWithUserState & StoreWithConfigState) => ({
    subscriber: getSubscriber(state),
    homeAirport: getHomeAirport(state),
    siteId: getSiteId(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AirSearchFormState, void, AnyAction>) => ({
    dispatchUpdateSubscriber: async (subscriber: Subscriber) => {
        return dispatch(addOrUpdateAwdSubscriber({ subscriber }));
    }
});

const mergeProps = (
    stateProps: { subscriber?: Subscriber; siteId: AwdSiteId; homeAirport?: Airport },
    dispatchProps: { dispatchUpdateSubscriber: (subscriber: Subscriber) => Promise<void> },
    ownProps: OwnProps
) => ({
    ...ownProps,
    onDestinationClick: async (destination: Airport) => {
        const { subscriber, siteId, homeAirport } = stateProps;

        if (!subscriber) {
            throw new Error('User not signed-in. Failed to add subscription.');
        }

        const { dispatchUpdateSubscriber } = dispatchProps,
            newSubscriptions = getAwdSubscriptionForAirports({
                departureAirportCode: homeAirport?.airportCode,
                arrivalAirportCode: destination.airportCode,
                arrivalCityId: destination.city.id,
                siteId
            }),
            updatedSubscriber = { ...subscriber, subscriptions: newSubscriptions };

        return dispatchUpdateSubscriber(updatedSubscriber);
    }
});

const ConnectedTrackDestinationsCarousel = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(TrackDestinationsCarousel);

export default ConnectedTrackDestinationsCarousel;
