import { getCurrency, StoreWithUserState } from 'ducks/user';
import { getLocale, StoreWithConfigState } from 'ducks/config';
import { AnyAction, Store } from 'redux';
import { CurrencyCode } from 'models/Currency';
import { LanguageTag, Locale } from 'models/Locale';
import React, { ReactNode } from 'react';
import { connect, Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getMessageOverrides } from 'messages';

type BaseStore = StoreWithUserState;

/**
 * For dollar-based currencies, the locale passed to the react-intl provider must match the currency.
 * Otherwise if you have, say, CAD as a currency but languageTag of en-US prices will display prefixed by
 * "CA$" instead of "$".
 */
function getLanguageTag({ siteLocale, currency }: { siteLocale: Locale; currency: CurrencyCode }): LanguageTag {
    switch (currency) {
        case CurrencyCode.CAD:
            return LanguageTag.enCA;
        case CurrencyCode.USD:
            return LanguageTag.enUS;
    }

    return siteLocale.languageTag;
}

/**
 * Component containing shared redux and react-intl bindings used across our apps.
 *
 * @param store
 * @param currency
 * @param siteLocale
 * @param children
 * @constructor
 */
function AwdProvider({
    store,
    currency,
    siteLocale,
    children
}: {
    store: Store<BaseStore, AnyAction>;
    currency: CurrencyCode;
    siteLocale: Locale;
    children: ReactNode;
}) {
    const languageTag = getLanguageTag({ siteLocale, currency });

    return (
        <Provider store={store}>
            <IntlProvider locale={languageTag} defaultLocale={languageTag} messages={getMessageOverrides(languageTag)}>
                {children}
            </IntlProvider>
        </Provider>
    );
}

const mapStateToProps = (state: StoreWithUserState & StoreWithConfigState) => ({
    currency: getCurrency(state),
    siteLocale: getLocale(state)
});

export default connect(mapStateToProps)(AwdProvider);
