import * as Sentry from '@sentry/browser';
import SentryConfig, { SentryEnvironment } from 'models/SentryConfig';
import { Buffer } from 'buffer';
import get from 'lodash/get';

/**
 * Error messages corresponding to events we don't want to report to Sentry because we have decided
 * to ignore them and we don't want them to keep counting against our quota. When adding an error
 * please add a reference to the issue in Sentry so we can have some sort of paper trail for why
 * we're ignoring certain errors.
 *
 * Mote that if we're really getting slammed with a certain error, it might be worth adding it
 * to the custom inbound filters in the Sentry UI as well:
 * https://sentry.io/settings/smartertravel/projects/airfarewatchdog/filters/data-filters/
 * That's because we have seen that there are always some old versions of our JS bundles out there
 * and that's the only way to tell Sentry to ignore them server-side. Either way, add them here
 * with a comment to the relevant issue so we have the reasoning documented
 */
const IGNORE_ERRORS = [
    "Can't execute code from a freed script", // TODO: Should be able to remove once blog frontend is migrated to WP https://sentry.io/organizations/smartertravel/issues/1421789204
    'TypeError: Object expected', // TODO: Should be able to remove once blog frontend is migrated to WP https://sentry.io/organizations/smartertravel/issues/1346517802/activity/
    "TypeError: Invalid operand to 'instanceof': Function expected", // TODO: Should be able to remove once blog frontend is migrated to WP https://sentry.io/organizations/smartertravel/issues/1346517802/activity/
    /TypeError: Unable to get property 'sqrt'.*/, // TODO: Should be able to remove once blog frontend is migrated to WP https://sentry.io/organizations/smartertravel/issues/1346517802/activity/
    /TypeError: Unable to get property 'round'.*/, // TODO: Should be able to remove once blog frontend is migrated to WP https://sentry.io/organizations/smartertravel/issues/1346517802/activity/
    "SyntaxError: Unexpected identifier 'paramMap'", // https://sentry.io/organizations/smartertravel/issues/1173045370/activity/
    "SyntaxError: Expected an identifier but found 'paramMap' instead", // Ibid.
    /.*BmDepature.*/, // https://sentry.io/organizations/smartertravel/issues/1367794636/activity
    /.*TRC.videoFunctions.*/, // https://sentry.io/organizations/smartertravel/issues/1262736731/activity/
    /.*window.addthis.addEventListener.*/ // https://sentry.io/organizations/smartertravel/issues/1246610838/activity/
];

/**
 * Regex matching URLS we want to blacklist, mostly because they are from 3rd party scripts over which we have no control
 */
const BLACKLISTED_URLS_REGEX = new RegExp(/(.*addthis_widget\.js)|(.*apstag\.js)/);

/**
 * Are there any frames in the provided set of frames that are associated with a URL that matches the provided Reg Exp
 * @param frames
 * @param urlRegex
 */
function framesIncludeUrl({ frames, urlRegex }: { frames: Sentry.StackFrame[]; urlRegex: RegExp }) {
    return frames.some(frame => frame.filename && urlRegex.test(frame.filename));
}

/**
 * Initializes the Sentry JS client.
 * @see https://docs.sentry.io/error-reporting/configuration/?platform=browser
 *
 * @param sentryConfig
 * @param domain
 * @param staticBaseUrl
 */
export const initializeSentry = ({
    sentryConfig,
    domain,
    staticBaseUrl
}: {
    sentryConfig: SentryConfig;
    domain: string;
    staticBaseUrl: string;
}) => {
    if (sentryConfig.enabled) {
        const whitelistRegExp = new RegExp(`(${domain}|^)${staticBaseUrl}.*.js`);

        Sentry.init({
            beforeSend: (event: Sentry.Event) => {
                // This shouldn't actually happen, but just in case
                if (!event) {
                    return null;
                }

                const frames: Sentry.StackFrame[] | undefined =
                    (event.stacktrace && event.stacktrace.frames) ||
                    get(event, 'exception.values[0].stacktrace.frames');

                // If we don't have a stacktrace, or if none of the filenames in the stacktrace match the whitelist,
                // or if one of the filenames matches the blacklisted URLs, then we should
                // filter out the event. This is in an effort to filter out third party errors that we don't care about
                // and/or can't do anything about. By default, it seems like if Sentry doesn't find a stacktrace or a filename/url
                // in the stacktrace, it counts the event as whitelisted.
                if (
                    !frames ||
                    !framesIncludeUrl({ frames, urlRegex: whitelistRegExp }) ||
                    framesIncludeUrl({ frames, urlRegex: BLACKLISTED_URLS_REGEX })
                ) {
                    return null;
                }

                // Attempting to send too much data will return a 413 "Request Entity Too Large" response, causing the error
                // to not be logged by Sentry. Currently, the max allowable size of an event is 200KiB, which can be hit if
                // extra data (such as the Redux state) being sent to Sentry is too large.
                // See: https://docs.sentry.io/learn/quotas/#attributes-limits
                if (event.extra && Buffer.byteLength(JSON.stringify(event)) > 200000) {
                    event.extra = { message: 'Sentry event too large, extra data omitted' };
                }

                return event;
            },
            dsn: sentryConfig.dsnUrl,
            environment: sentryConfig.environment,
            release: sentryConfig.release,
            // Sample rate set low for non-prod environments so that we can test initializing Sentry in those envs
            // without depleting our quota too much
            sampleRate: sentryConfig.environment === SentryEnvironment.PRODUCTION ? 0.05 : 0.01,
            // As of 1/6/2020, Sentry's docs claim that the whitelistUrls and blacklistUrls config fields are not
            // available for the JS SDK, which would explain why neither of the following two lines appears to
            // be working. See https://docs.sentry.io/error-reporting/configuration/?platform=javascript#blacklist-urls
            // However, elsewhere in the documentation they appear to reference these as if they _do_ work. So
            // I'm keeping these parameters in, in case they revive this functionality but also attempting to
            // handle them in the beforeSend field above
            whitelistUrls: [whitelistRegExp],
            blacklistUrls: [BLACKLISTED_URLS_REGEX],
            ignoreErrors: IGNORE_ERRORS
        });
    }
};
