/**
 * Front end representation of the SearchType.java enum.
 */
enum SearchType {
    AIR = 'air',
    AIR_FLEX = 'airflex',
    CAR = 'car',
    CRUISE = 'cruise',
    HOLIDAY = 'holiday',
    HOTEL = 'hotel',
    VACATION_PACKAGE = 'vacation',
    VACATION_RENTAL = 'vacation_rental'
}

export default SearchType;
