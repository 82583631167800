import thunk from 'redux-thunk';
import sentryMiddleware from 'middleware/sentry';
import { PageData } from 'models/PageData';
import { initializeSentry } from 'utils/sentry';
import { loadPolyfill } from 'utils/polyfill';
import { determineExperiment } from './experiment';

/**
 * Sets up components common to all of our React pages, e.g. configuring Sentry and conditionally loading polyfills,
 * then starts the app.
 * @param pageData
 * @param domain
 * @param startApp
 */
export function initializeApp({
    pageData,
    domain,
    startApp
}: {
    pageData: PageData;
    domain: string;
    startApp: () => void;
}) {
    const { sentryConfig, staticBaseUrl } = pageData;

    //To verify that we sent experiment seen
    determineExperiment('awd-feat-2');

    loadPolyfill(staticBaseUrl, () => {
        // Polyfill should be loaded before initializing Sentry - see https://docs.sentry.io/platforms/javascript/#browser-table
        initializeSentry({ sentryConfig, staticBaseUrl, domain });
        startApp();
    });
}

export function getGlobalMiddlewares(pageData: PageData) {
    return [thunk, sentryMiddleware(pageData.sentryConfig)];
}
