import Airport, { isAirportLocation } from 'models/Airport';
import { Location } from 'models/Location';
import { AirportSuggestion, SuggestionDecorator } from 'models/Suggestion';

export function getLocationNameDisplayMin(location: Location) {
    return location.names.names['display-min'];
}

export function getLocationNameDisplayMed(location: Location) {
    return location.names.names['display-med'];
}

export function getLocationNamePrimary(location: Location) {
    return location.names.names.primary;
}

export function getLocationDisplayNameWithAirport(location: Location, airportCode: string) {
    if (isAirportLocation(location)) {
        return getAirportDisplayName(location);
    }
    return `${getLocationNameDisplayMin(location)} (${airportCode})`;
}

export function getAirportDisplayName(airport: Airport) {
    return `${getLocationNameDisplayMin(airport.city)} (${airport.airportCode})`;
}

export function buildAirportSuggestion(airport: Airport): AirportSuggestion {
    const { id, airportCode, primaryName, city } = airport;
    return {
        id,
        airportCode,
        displayName: primaryName,
        parentDisplayName: city.primaryName,
        parentId: city.id
    };
}

export function buildAirportSuggestionDecorator(airport: Airport): SuggestionDecorator<AirportSuggestion> {
    return { text: getAirportDisplayName(airport), suggestion: buildAirportSuggestion(airport) };
}

export function toDisplayText(airport: Airport | AirportSuggestion): string {
    if (isAirport(airport)) {
        return getLocationNameDisplayMin(airport.city) + ` (${airport.airportCode})`;
    } else {
        return airport.displayName;
    }
}

export function isAirport(location: Airport | AirportSuggestion): location is Airport {
    return (location as Airport).city !== undefined;
}
