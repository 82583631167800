import { CurrencyCode } from 'models/Currency';
import { CountryCode } from 'models/Locale';
import { setCookie } from 'utils/cookies';
import { CookieNames } from 'constants/cookies';

export function getCountryForCurrency({ currency }: { currency: CurrencyCode }): CountryCode {
    switch (currency) {
        case CurrencyCode.CAD:
            return CountryCode.CA;
        case CurrencyCode.GBP:
            return CountryCode.GB;
        case CurrencyCode.USD:
            return CountryCode.US;
    }
}

/**
 * Stores the provided currency in the cookie we pass to the server to
 * keep track of users' choice
 *
 * @param data
 */
export function setCurrencyCookie({ currency }: { currency: CurrencyCode }) {
    setCookie({ name: CookieNames.CURRENCY, value: currency, expireInDays: 360 });
}
