import { LanguageTag } from 'models/Locale';

import enGB from './en-gb';
import enUS from './en-us';

const messages: { [language: string]: { [msgId: string]: string } } = {
    [LanguageTag.enGB]: enGB,
    [LanguageTag.enUS]: enUS
};

/**
 * Returns a map with all the global message overrides for the specified locale.
 * The returned messages are matched by "react-intl" to existing default messages
 * using their unique message ids.
 *
 * Note that we currently return all message overrides for all bundles/pages for
 * each locale. We do so because this is simpler and we really have very few message
 * overrides (i.e. we mostly rely on default messages, which are defined within each
 * component/page directory). But if the number of message overrides begins to grow
 * we should consider splitting the messages up and returning only the subset that is
 * needed for each bundle.
 *
 * @param locale
 * @returns { [msgId: string]: string }
 */
export const getMessageOverrides = (languageTag: LanguageTag) => {
    return messages[languageTag];
};
