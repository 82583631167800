/**
 * Front end representation of the SearchRoom.java enum
 */
enum Rooms {
    ONE = '1',
    TWO = '2',
    THREE = '3',
    THREE_PLUS = '3+'
}

export default Rooms;
