/**
 * Frontend representation of the UserStatus class used by the subscription service
 * (package com.smartertravel.subscriptions.types.UserStatus.java)
 */
enum UserStatus {
    DO_NOT_MAIL = 'donotmail',
    UNDELIVERABLE = 'undeliverable',
    VALID = 'valid'
}

export default UserStatus;
