import Airport from 'models/Airport';
import { buildQueryParamString } from 'utils/urls';
import TopLocationsResponse from 'models/TopLocationsResponse';

const TOP_LOCATIONS_ENDPOINT = '/top-locations';

export async function getTopLocations({
    origin,
    limit,
    includeImages
}: {
    origin?: Airport;
    limit?: number;
    includeImages?: boolean;
} = {}): Promise<TopLocationsResponse> {
    const qs = buildQueryParamString({ limit, origin: origin?.airportCode, ['include-images']: includeImages }),
        res = await fetch(TOP_LOCATIONS_ENDPOINT + '/' + qs);

    if (!res.ok) {
        throw new Error(`Failed to fetch top locations"`);
    }

    return (await res.json()).success;
}
