// START: AWD-3728
import React from 'react';
import ReactDOM from 'react-dom';
import Media from 'react-media';
import { AwdWindow } from 'models/AwdWindow';
import { translateExternalSearchDataWithAirports } from 'ducks/airSearchData';
import { RecentSearchCollapsible, RecentSearchWide } from 'components/RecentSearch';
import { buildFareDetailsUrl } from 'utils/urls';
import { isSearchDataWithLocations } from 'models/SearchData';
import { AbTestName, AbTestRecipe } from 'constants/abTests';
import { mediaXs } from 'constants/mediaQueries';
import { getRecentAirSearchData } from 'api/recentActivity';
import { ExternalSearchData } from 'models/incomingData/SearchData';
import { AnyAction, Store } from 'redux';
import { StoreWithUserState } from 'ducks/user';
import { StoreWithConfigState } from 'ducks/config';
import AwdProvider from 'components/AwdProvider';

declare const window: AwdWindow;

export async function renderRecentSearchModule({
    store
}: {
    store: Store<StoreWithUserState & StoreWithConfigState, AnyAction>;
}) {
    const { abTests } = window.pageData,
        isRecentSearchModuleTest =
            abTests && abTests[AbTestName.AWD_3728] && abTests[AbTestName.AWD_3728].recipe === AbTestRecipe.AWD_3728_B;
    let externalRecentSearchData: ExternalSearchData = {};

    if (!isRecentSearchModuleTest) {
        return;
    }

    try {
        externalRecentSearchData = await getRecentAirSearchData();
    } catch (e) {
        // If we fail to fetch recent search data, there's nothing we can do
        return;
    }

    const recentSearchData = translateExternalSearchDataWithAirports(externalRecentSearchData);

    if (isSearchDataWithLocations(recentSearchData)) {
        ReactDOM.render(
            <AwdProvider store={store}>
                <Media query={mediaXs}>
                    {matches =>
                        matches ? (
                            <RecentSearchCollapsible
                                searchData={recentSearchData}
                                onClick={() => window.open(buildFareDetailsUrl(recentSearchData), '_blank')}
                            />
                        ) : (
                            <RecentSearchWide
                                searchData={recentSearchData}
                                onClick={() => window.open(buildFareDetailsUrl(recentSearchData), '_blank')}
                            />
                        )
                    }
                </Media>
            </AwdProvider>,
            document.getElementById('react-root__recent-search') as HTMLElement
        );
    }
}
// END: AWD-3728
