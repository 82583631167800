export enum AbTestName {
    AWD_3560 = 'AWD-3560', // Calendar View in fare details page
    AWD_3728 = 'AWD_3728', // Recent search module
    AWD_3430 = 'AWD-3430', // 3Victors Modal in Airlines homepage
    AWD_3860 = 'AWD-3860' // Top locations carousel
}

export enum AbTestRecipe {
    AWD_3560_CALENDAR_VIEW = 'calendarView',
    AWD_3560_DEFAULT = 'default',
    AWD_3728_A = 'A',
    AWD_3728_B = 'B',
    AWD_3430_DEFAULT = 'default',
    AWD_3430_POSTSEARCH = 'postsearch',
    AWD_3430_TIMED = 'timed',
    AWD_3860_TOP_LOCATIONS = 'topLocations'
}
